
.live-area {
  z-index: 1;
  position: relative;
  img {
    max-width: 100%;
  }
}

section {
  padding: 0 0;
  display:block;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;

}

article {
  display:block;
  position:relative;
}
aside section { padding: 1em 0 ; }


.md-flex-row-stretch,.md-row-stretch {
  @media (min-width: @screen-md-min) {
    display: flex;
    align-items: stretch;
  }
}
.sm-flex-row-stretch,.sm-row-stretch {
  @media (min-width: @screen-sm-min) {
    display: flex;
    align-items: stretch;
  }
}
.sm-flex-row-center,.sm-row-center {
  @media (min-width: @screen-sm-min) {
    display: flex;
    align-items: center;
  }
}

.flex-row-stretch, .row-stretch {
  @media (min-width: @screen-lg-min) {
    display: flex;
    align-items: stretch;
  }
}

.md-flex-row-center, .md-row-center {
  @media (min-width: @screen-md-min) {
    display: flex;
    align-items: center;
  }
}

.flex-row-center, .row-center {
  @media (min-width: @screen-lg-min) {
    display: flex;
    align-items: center;
  }
}






.border-left { border-left: 1px solid @brand-primary; }


div[class*="border-left-xs"]{  padding-left: ( @grid-gutter-width * @xs-pad );}
div[class*="border-left-md"]{ padding-left: ( @grid-gutter-width * @md-pad );}
div[class*="border-left-lg"]{ padding-left: ( @grid-gutter-width * @lg-pad );}
div[class*="border-left-xl"]{ padding-left: ( @grid-gutter-width * @xl-pad );}

.border-left-xs,
.border-left-md,
.border-left-lg,
.border-left-xl
{
  border-left: .75em solid ;
  &-yellow {
    border-left: .75em solid @border-color;
  }
}


.h-row {
  margin-bottom:1.5em;
}



