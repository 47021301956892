.arrowed-link-before {
  position:relative;
  &:before {
    display: inline-block;
    position: absolute;
    line-height: 1em;
    height: 1em;
    left : auto;
    margin-left: -1.5em;
    width: 1em;
    font-family: @brand-icon-font;
    content: "\e95f";
    top: 50%;
    color: inherit;
    margin-top: -.45em;
    font-size: .5em;
    vertical-align: bottom;
  }
}

.btn-arrows {
  overflow: hidden;
  position: relative;
  z-index:2;

  //&:hover { background: transparent !important;}
 &:after {
   display: inline-block;
   position: relative;
   line-height: 1em;
   height: auto;
   left : auto;
   font-weight: 400;
   margin-left: 0.125em;
   width: 1em;

   font-family: @brand-icon-font;
   content: '\e960';
   color: inherit;
   vertical-align: middle;
   transform: translateX(0);
   transition: transform .25s ease-in-out;
 }
  &:before {
    color: inherit;
    letter-spacing: -1px;
    font-family: @brand-icon-font;
    display: inline-block;
    font-weight: 400;
    position: absolute;
    content: '\e962\e963';
    width:2em;
    text-align: right;
    z-index: -1;
    font-size: 16em;
    line-height: 0;
    transform: translateX(-101%);
    padding: .25em 0em .25em .25em;
    right: 0px;
    top: 50%;
    margin-top: -.25em;
    transition: transform .25s ease-in-out;
  }
  &:hover,&:focus,&:active {
    &:before {
      transform: translateX(-.19em);
    }
    &:after {

      transform: translateX(3em);
    }
  }
  &.btn-line {
    //&:before {
    //  cotent: '';
    //  width:0px;
    //  padding:0px;
    //  display: none;
    //}
  }
}
.arrowed-heading {
  &:before {
    font-size:.75em;
    display: inline-block;
    width: 1em;
    font-family: @brand-icon-font;
    content: "\e95f";
    color: inherit;
    margin-right: .25em;
    margin-left: 0px;
    position: relative;
    height: auto;
    vertical-align: baseline;
    margin-top: 0px;
    line-height: 1em;
  }
}