section.info-graphic {
  //background: @brand-gray-dark;
  @local-pad: (@xs-pad * @grid-gutter-width );

  .container {







    padding:@local-pad;
    padding-top:0px;
    padding-bottom:0px;
    .bg-gray();
    .title-row,.img-row{
      padding-bottom:  @local-pad;
    }
    .title-row {
      padding-bottom: @local-pad;
      display:none;
      .headings {
        .arrowed-heading();
        &:before{
          color: @brand-green;
        }
      }
    }
    .img-row {
      //padding: (@grid-gutter-width /2 ) @local-pad;
      padding-bottom:  @local-pad;
    }
    .list-row {
      .column {

      }
      ol {
        //padding-top:2em;
        //border-top: 1px solid #fff;
        counter-reset: dacounter;
        padding-left: 0px;

        li {
          box-sizing: border-box;
          position:relative;
          padding-left: 3.5em;
          margin-bottom: 1em;
          padding-right: 1em;
          display: inline-block;
          &:before {
            counter-increment: dacounter;
            content: counter(dacounter);
            display:block;
            position:absolute;
            text-align:center;
            font-size:1.5em;
            height:1.5em;
            width:1.5em;
            left:0em;
            top:0.125em;

            line-height:1.5em;
            background:#fff;
            font-family: @font-family-base;
            font-weight: 800;
            font-style: normal;
            color: @brand-gray-dark;
            box-shadow: 0px 0px 7px 3px rgba(0,0,0,.5);

          }
        
        }
      }
      .column {

      }
      @media (min-width: @screen-md-min) {
        ol {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
        }
        .split-2 { ol li { width: 49.5%; } }
        .split-3 { ol li { width: 33%;   } }
        .split-4 { ol li { width: 24.5%; } }
        .split-2 {

        }

      }
    }
  }
}
//
//.page-id-195 {
//  section , section div.row {
//    position:relative;
//    &:after {
//      content: attr(class);
//      font-weight: 800;
//      font-size: 14px;
//      padding:10px;
//      right: 0px;
//      width: 100px;
//      color: @brand-green;
//      top:0;
//      background: @brand-gray-dark;
//      position:absolute;
//    }
//
//  }
//}