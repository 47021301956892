footer {

  display:block;
  float:left;
  position:relative;
  width:100%;
  font-family: @headings-font-family;
  font-size:18px;
  line-height: 1.2em;
  ul { padding-left:0px; list-style:none;  }


  .before-footer {

    .pad-xs-vert();
    text-align:center;
    border-top:1px dotted @footer-color-2;
   // .bg-variants(@footer-color-2, @footer-before-text ,~'darken', @brand-green, @footer-color-2);
    font-weight: 600;
    text-transform:uppercase;
    display: none;
    @media(min-width:@screen-xs-min) {
      display: block;
      .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .row > div {
        display: inline-block;
      }
    }
    a { font-weight: 100; color:#fff; &:hover { color:@footer-hover; } }
    img {max-width: 100%; padding: 0px 12px;}
    p {
      margin-top: .125em;
      &.company-title {font-size:.75em; line-height:1em;  color: @footer-color-1;}
    }

  }
  .center-footer {
    ul {
       > li { margin-bottom: .75em; > a { border-bottom: 1px solid @footer-text; padding: .5em 0em; }}
       ul > li{ margin-bottom:0px; > a { font-weight: 400; font-family: @font-family-base;border-bottom: 0px solid transparent;}}
      font-weight: 600;
      a { display: inline-block; }
      li a{ margin-bottom: .25em; width: 100%;}
      ul {
        margin-bottom:0px;
        font-size: .8em;
        text-transform: none;
        font-weight: 300;
        li a{ padding: .125em 0em;}
      }
    }
    .pad-md-vert();
    .bg-variants(@footer-color-1, @footer-text ,~'darken', @brand-green, @footer-color-1);

    a { color: inherit; &:hover { color:@footer-hover; }  }
    @media( max-width: @screen-lg-min) and (min-width: @screen-xs-min) {
      .row.footer-widget-area {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

      }
      .row.footer-widget-area {
        @media(min-width: @screen-xs-min) {
          > div {
            text-align: left;
            width: 50%;
          }
        }
      }
    }
    @media(max-width: @screen-xs-min){
      display: none;
      text-align:center;
      .footer-widget-area {
        .widget_nav_menu {
          padding-bottom:.0em;
          padding-top:0em;
          padding-left:50px;
          padding-right: 50px;
          //border-bottom: 1px solid #000;
          ul {
            li {
              margin-top:.5em;
              margin-bottom:.5em;
              }
            }
          }
        }
      }
  }
  .after-footer {
    .pad-xs-vert();
    .bg-variants(@footer-color-1, @footer-text ,~'darken', @brand-green, @footer-color-1);
    border-top: 1px solid @brand-gray-light;
    font-size:.6em;
    font-weight: 500;
    text-transform:uppercase;
    font-family: @font-family-base;
    i,span.b-icon { color: @footer-color-1; }
    a { color: inherit; &:hover { color:@footer-hover; }  }

    .logo-link #footer-logo{
      //height: 96px;
      //width: 175px;
      height: @footer-logo-height;
      width: @footer-logo-width;

      position: relative;
      background: @footer-logo-fallback, @footer-logo-color-fallback;
      background: @footer-logo, @footer-logo-color-fallback;
      background-size: cover;
      display: block;

    }
    .col-2 {text-align:right;}
    @media( min-width: @screen-lg-min) {
      .logo-container {width: auto;}
      .col-1 { float: left; width: auto;}
      .col-2 { float:right; width: auto;}
      .social-links{ float: right; width: auto;}
    }

    @media( max-width: @screen-md-min) {
      .logo-link { display: inline-block; }
      .logo-container {text-align:center; float:left; width:100%; }
      .col-1, .col-2 {text-align: center; margin-bottom:.5em; margin-top:.5em; width: 100%;}
      .col-1 {float: right;}
      .col-2 {float: left;}
      .social-links { text-align:center; float:right; width:100%;}
    }
  }
}
