
.button-variant-line(@color; @background; @border; @before-color;) {

  color: @color;
  background-color: transparent;
  border-color: @border;
  border-width: @btn-line-width;
  position:relative;
  overflow:hidden;

  &:before {
    color: @color ;
  }

  &:focus,
  &.focus,&:hover {
    color: @before-color;
    //color: lighten(@color,25%);
    //background-color: @btn-line-bg;
    //border-color: lighten(@border, 25%);
    //outline: lighten(@border,25%) 1px solid;
    &:after { color: @color;}
    &:before {
      color: @color ;
      }
    }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: @background;
      border-color: @border;
      }
    }
  }

