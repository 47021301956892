
.home-page-header {
  margin-bottom: 3em;
  @media(min-width: @screen-md-min) {

    font-size: 14px;
  }
  z-index:1;
  position: relative;
  &:after {
    position: absolute;
    bottom: -1px;
    right: 0;
    font-size: 3em;
    line-height: 0;
    vertical-align: bottom;
    width: 1em;
    height: .5em;
    color: #fff;
    content: '\e961';
    font-family: @brand-icon-font;
  }
  .homepage-vidbox {
    display:flex;
    align-items: center;
    z-index:-1;
    position:absolute;
    width:100%;
    height: 100%;
    overflow:hidden;
    &:before {
      z-index: 1;
      position: absolute;
      @faded : fade( @brand-gray-dark ,0%);
      background: radial-gradient(ellipse at center, @faded 0%, @brand-gray-dark 100%);
      opacity: 0.95;
      content: '';
      height: 100%;
      width: 100%;
      display: block;
      left: 0;
      top: 0;
    }
    video{
      width: 100% !important;
      height: auto !important;
      display: inline-block;
      vertical-align: middle;
      z-index: -1;
      @media(max-width: @screen-sm-max) { display:none !important; }
    }

  }
  .row {
    .column {
      &.special-column {

        max-width: 85%;
        max-width: ~'calc( 100% - 40px )';
        img {
          margin-bottom: .5em;
        }
        padding-top: 3em;
        padding-bottom: 3em;
        &:before {
          display: block;
          width: 300%;
          content: '';
          background: url('../images/special-BG.png');
          background-size: auto 100%;
          background-repeat:no-repeat;
          height: 100%;
          background-position: bottom right;
          position: absolute;
          right: -20px;
          opacity: .9;
          top: 0px;
          z-index: -1;
        }
      }
    }
  }
}
@special-pad : 15px;
.double-bg-image-bordered {
  .row {
    .column {
      background-size: cover;
      background-position: left;
      background-repeat: no-repeat;
      + .column {
        background-position: right;
      }
      h2 {
        white-space: break-word;
      }
    }
    @media (max-width: @screen-md-min) {
      .column {
        background-position: top right;
        +.column {
          background-position: top left;
        }
        padding-top:2em;
        padding-bottom:3em;
        border-bottom: 5px solid @brand-text-offwhite;
        .col {

          //text-align:center;
          +.col {
            border-top:1px solid #fff;
            border-left: 0px;
            ul {
              text-align: left;
              //width:auto;
              display: inline-block;
            }
          }
        }
        + .column {
          border-bottom: 0px;
        }
      }

      .column {
        .col {
          position: relative;


          i {
            font-size: 3em;
            margin-bottom: 10px;
          }
          padding-top:1em;


          padding-right: @special-pad;
          + .col {
            padding-bottom:1em;
            padding-left: @special-pad;
            padding-right: 0px;
            //border-left: 1px solid @brand-text-offwhite;
          }
        }
      }
    }

    @media (min-width: 486px) {
      .column {
        display: flex;
        align-items: stretch;
        .col {
          text-align: left;
          position: relative;
          i {
            margin-top: .5em;
            margin-bottom: .25em;

          }
          + .col {
            border-top: 0px;
            border-left: 1px solid #fff ;
          }
          width: 50%;


        }
      }
    }
    @media(max-width: 485px) {
      .column {
        .col {
          h2 {
            margin-bottom: 15px;
          }
          ul {
            margin-bottom: 0px;
          }
          padding-left: 15px;
          i {
            //margin-left: 15px;
            margin-top: .25em;
            margin-bottom: .5em;
          }

        }
      }
    }
    @media (min-width: @screen-md-min) {
      .column {
        padding-top:2em;
        padding-bottom:3em;
        border-right: 10px solid @brand-text-offwhite;
        + .column {
          border-right: 0px solid @brand-text-offwhite;
          border-left: 10px solid @brand-text-offwhite;
        }
      }
      .column {
        ul {
          li:before {
            color: @brand-green;
          }
        }
        .col {
          position: relative;
          padding-bottom: 60px;
          i {
            position:absolute;
            bottom:-20px;
            left: 0px;
            font-size: 3em;
          }
          padding-top:1em;

          width: 50%;
          padding-right: @special-pad;
          + .col {
            padding-bottom:1em;
            padding-left: @special-pad;
            padding-right: 0px;
            border-left: 1px solid @brand-text-offwhite;
          }
        }
      }
    }
  }
}
.people-driven {
  background: #fff;
  .pad-sm-vert();
  .row {
    .column {
      padding-top: 1em;
      padding-bottom: 1em;
      @media (min-width: @screen-md-min) {
        //padding-right: 2em;
        + .column {
          //padding-right: 25px;
          //padding-left: 2em;
          border-left: 1px solid @brand-primary;
        }
      }
    }
  }
}