// Global template background fixes.
section {
  background-size: cover;
}

// fixes section background default.
// Required Repeat Fixes

.repeat-fixes(@primary-color,@text-color) {
  h1, h2, h3, h4, h5, h6, p, li {
    color: @text-color;
  }
  i,span.b-icon { color: @primary-color; }
  a { color: @primary-color; &:hover { color: @primary-color;
    text-decoration: underline; } }
  ul {
    li {
      a {color: @text-color;}
    }
    li:before {
      color: @primary-color;
    }
  }
  hr {
    border-top-color:@text-color ;
  }
}





// Global Background-mixins ----------------------------------------------------------------------------------------

// used to add bg color modifiers
.bg-variants(@color; @text-color; @modifier; @primary-color; @primary-text-color;) {
  background: @color;
  color: @text-color;
  .repeat-fixes(@primary-color,@text-color);
  .btn {
    /// special defaults
    text-decoration: none;
     &:hover { text-decoration:none;}
    i,span.b-icon { color: inherit !important;  }
    &.btn-default {
      color: @color;
      background-color: @text-color;
      outline-color: @text-color;
      border-color: @text-color;
      &:hover, &:focus {
        color: @text-color;
        background: @text-color;

        &:before {
          color: @color;
        }
        &:after {
          color: @color;
        }
      }
    }
    &.btn-primary, &.btn-primary.btn-default, &.btn-brand, &.btn-brand.btn-default {
      color: @primary-text-color;
      background-color: @primary-color;
      outline-color: @primary-color;
      border-color: @primary-color;
      &:before {
        color: @color;
      }
      &:hover, &:focus {
        color: @primary-color;
        background: @primary-color;

        &:before {
          color: @color;
        }
        &:after {
          color: @primary-text-color;
        }
      }
    }

    &.btn-default.btn-line {
      color: @text-color;
      background-color: transparent;
      border-color: @text-color;
      &:before {
        color: @text-color;
      }
      &:hover, &:focus {
        color: @color;

        &:before {
          color: @text-color;
        }
        &:after {
          color: @text-color;
        }
      }
    }
    &.btn-primary.btn-line, &.btn-primary.btn-line.btn-default, &.btn-brand.btn-line, &.btn-brand.btn-line.btn-default {
      color: @primary-color;
      background: transparent;
      border-color: @primary-color;
      &:before {
        color: @primary-color;
      }
      &:hover, &:focus {
        color: @color;
        outline-color: @primary-color;
        border-color: @primary-color;
        background: transparent;
        &:before {
          color: @primary-color;
        }
        &:after {
          color: @primary-color;
        }
      }
    }

  }
}

// used to add support for mask style color overlays on images
.bg-variants-image(@color; @text-color; @primary-color; @primary-text-color; @overlaycolor; ) {
  z-index: 2;
  color: @text-color;
  &:before {
    z-index: -1;
    position: absolute;
    background: @overlaycolor;
    opacity: .75;
    content: '';
    height: 100%;
    width: 100%;
    display: block;
    left: 0;
    top: 0;
  }
  .repeat-fixes(@primary-color,@text-color);
  .btn {
     text-decoration: none;
     &:hover { text-decoration:none;}
    i,span.b-icon { color: inherit !important;  }
    /// special defaults
    &.btn-default {
      color: @color;
      background-color: @text-color;
      outline-color: @text-color;
      border-color: @text-color;
      &:hover, &:focus {
        color: @text-color;
        background: @text-color;

        &:before {
          color: @color;
        }
        &:after {
          color: @color;
        }
      }
    }
    &.btn-primary, &.btn-primary.btn-default, &.btn-brand, &.btn-brand.btn-default {
      color: @primary-text-color;
      background-color: @primary-color;
      outline-color: @primary-color;
      border-color: @primary-color;
      &:before {
        color: @color;
      }
      &:hover, &:focus {
        color: @primary-color;
        background: @primary-color;

        &:before {
          color: @color;
        }
        &:after {
          color: @primary-text-color;
        }
      }
    }

    &.btn-default.btn-line {
      color: @text-color;
      background-color: transparent;
      border-color: @text-color;

      &:before {
        color: @text-color;
      }
      &:hover, &:focus {
        color: @color;

        &:before {
          color: @text-color;

        }
        &:after {
          color: @text-color;
        }
      }
    }
    &.btn-primary.btn-line, &.btn-primary.btn-line.btn-default, &.btn-brand.btn-line, &.btn-brand.btn-line.btn-default {
      color: @primary-color;
      background: transparent;
      border-color: @primary-color;
      &:before {
        color: @primary-color;

      }
      &:hover, &:focus {
        color: @color;
        outline-color: @primary-color;
        border-color: @primary-color;
        background: transparent;
        &:before {
          color: @primary-color;
        }
        &:after {
          color: @primary-color;
        }
      }
    }

  }
}

// describes mixin for patterning a section with columns that need to have color stretch left and right respectively
.bg-half-mixin( @right-bg,@right-display,@left-bg,@left-display) {
  overflow: hidden;
  position: relative;
  .container .row {
    .md-flex-row-center();
    align-items: center !important;
    div {
      vertical-align: middle;
      img {
        display: inline-block;
      }
    }
  }

  .container .row > div:first-child {
    @media ( min-width: @screen-sm-max ) {
      padding-left: 0px;
      padding-right: (@grid-gutter-width * 1.5);
    }
    @media ( max-width: @screen-sm-max ) {
      padding-left: @grid-gutter-width;
      padding-right: @grid-gutter-width;
    }
    &:after {
      content: '';
      z-index: -1;
      display: @right-display;
      position: absolute;
      width: 2000%;
      height: 2000%;
      right: 0%;
      top: -100%;
      background: @right-bg;
      @media (max-width: @screen-md-min) {
        right: -100%;
        top: 0;
        height: 100%;
      }
    }
  }
  .container .row > div:last-child {
    @media ( min-width: @screen-sm-max) {
      padding-right: 0px;
      padding-left: (@grid-gutter-width * 1.5);
    }
    @media ( max-width: @screen-sm-max ) {
      padding-left: @grid-gutter-width;
      padding-right: @grid-gutter-width;
    }
    &:after {

      content: '';
      z-index: -1;
      display: @left-display;
      position: absolute;
      width: 2000%;
      height: 2000%;
      left: 0%;
      top: -100%;
      background: @left-bg;
      @media (max-width: @screen-md-min) {
        left: -100%;
        top: 0;
        height: 100%;
      }
    }
  }
}

// Global Background-mixins  END ----------------------------------------------------------------------------------------

i,span.b-icon { color: @brand-primary; }
.btn {
  i,span.b-icon { color: inherit;  }
}
ul {
  li {
    a {
      color: @text-color;
    }
  }
}






// BG MIXIN CALLS ----------------------------------------------------------------------------------------------------------

// bg-variants-image(@color; @text-color;)
// color is used to define background color overlay and text color is used to define inhereted text color.
.bg-full-overlay-dark {
  .bg-variants-image(@brand-gray-dark, @brand-text-offwhite, @brand-green, @brand-gray-dark, @brand-gray-dark);
}

.bg-full-overlay-light {
  .bg-variants-image(@brand-text-offwhite, @brand-gray-dark, @brand-primary, @brand-text-offwhite, @brand-text-offwhite);
}

.bg-image-dark {
  @local: rgba(0, 0, 0, 0);
  .bg-variants-image(@brand-gray-dark, @brand-text-offwhite, @brand-green, @brand-gray-dark, @local);
}

.bg-image-light {
  @local: rgba(0, 0, 0, 0);
  .bg-variants-image(@brand-text-offwhite, @brand-gray-dark, @brand-primary, @brand-gray-dark, @local);
}

.bg-image-dark-overlay {
  @local: @brand-gray-dark;
  .bg-variants-image(@brand-gray-dark, @brand-text-offwhite, @brand-green, @brand-gray-dark, @local);
}

.bg-image-light-overlay {
  @local: @brand-text-offwhite;
  .bg-variants-image(@brand-text-offwhite, @brand-gray-dark, @brand-primary, @brand-gray-dark, @local);
}

.bg-light-overlay {
  @local: @brand-text-offwhite;
  .bg-variants-image(@brand-text-offwhite, @brand-gray-dark, @brand-primary, @brand-gray-dark, @local);
}

.bg-dark-overlay {
  @local: @brand-gray-dark;
  .bg-variants-image(@brand-gray-dark, @brand-text-offwhite, @brand-green, @brand-gray-dark, @local);
}

// bg-variants(@color; @text-color; @modifier;)
// color describes background color textcolor describes text color this modifies links as well as link styles.
// modifier is proposed manipulator to darken or lighten of hover styles all hard coded for right now.

//.bg-white {
//  .bg-variants( #fff,@brand-black,~'darken',);
//}
//.bg-black {
//  .bg-variants(@brand-black, @brand-text-offwhite,~'darken');
//}

.bg-gray {
  .bg-variants(@brand-gray-dark, @brand-text-offwhite, ~'darken', @brand-green, @brand-gray-dark);
}

.bg-lgray {
  .bg-variants(@brand-gray-exlight, @text-color, ~'darken', @brand-primary, #fff);
}
.bg-white-clip {
  .bg-variants(#fff, #000, ~'darken', @brand-primary, @brand-gray-dark);
  background-clip: padding-box;
  border-left: (@grid-gutter-width/2) solid transparent;
  border-right: (@grid-gutter-width/2) solid transparent;
  padding-top: (@grid-gutter-width/2);
  padding-bottom: (@grid-gutter-width/2);
}
 
.bg-primary {
  .bg-variants(@brand-primary, #fff, ~'darken', @brand-green, @brand-gray-dark);
}

// bg-half-mixin( @right-bg,@right-display,@left-bg,@left-display)
// right& and left -bg describe background color of div and after element on respectable sides.
// display discribes how the after element is displayed used block for yes and none for not displayed.
// individual columns must be styled to match this property however.

.bg-half-gr {
  .bg-half-mixin(@brand-black, block, @bg-half-color, block);
}

.bg-half-gl {
  .bg-half-mixin(@bg-half-color, block, @brand-black, block);
}

.bg-half-image-right {
  @media (max-width: @screen-sm-max) {
    background-size: auto 75%;
    background-position: bottom center;
  }
  .bg-half-mixin(@brand-black, block, transparent, none);
}

.bg-half-image-left {
  @media (max-width: @screen-sm-max) {
    background-size: auto 75%;
    background-position: top center;
  }
  .bg-half-mixin(transparent, none, @brand-black, block);

}

//BACKGROUND MODIFIER CLASSES ----------------------------------------------------------------------------------------

.bg-left-75 {
  background-position: 0% 50% !important;
  background-position-x: 0% !important;
  background-position-y: 50% !important;
  background-size: 50% !important;
  background-repeat: no-repeat !important;
  .bg-half-image-left();
}

.bg-right-75 {
  background-position: 100% 50% !important;
  background-position-x: 100% !important;
  background-position-y: 50% !important;
  background-size: 50% !important;
  background-repeat: no-repeat !important;
  .bg-half-image-right();
}

.bg-center {
  background-position: center center !important;
}
.bg-repeat-vert {
  background-size: 100%;
  background-repeat: repeat-y;
}

.bg-cover {
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-fixed {
  background-attachment: fixed;
}