// GRAVITY FORMS

.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_first, .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_last, .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_middle {
  padding-top: 0px;
}

.gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=email], .gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=number], .gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=password], .gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=tel], .gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=text], .gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=url], .gform_wrapper .ginput_complex input[type=email], .gform_wrapper .ginput_complex input[type=number], .gform_wrapper .ginput_complex input[type=password], .gform_wrapper .ginput_complex input[type=tel], .gform_wrapper .ginput_complex input[type=text], .gform_wrapper .ginput_complex input[type=url] {
}

.gform_wrapper ul li.gfield {
  clear: none !important;
}

.gform_wrapper .gform_footer {
  width: 100%;
  padding: 1em 1em;
}

.gform_fields {
  list-style: none;
  padding-left: 0px;
}

//GLOBAL GFORMS STYLES

.gform_wrapper {
  margin-right: 0px;
  max-width: 100%;
  font-size:14px;

  ul li hr {
    padding: 0px 0px 0px 0px !important;
    margin-top: .5em !important;
    margin-bottom: .5em !important;
  }
  .gform_body ul li {
    vertical-align: top;
    padding-left: 15px;
    padding-right: 15px;
    &:before {
      content: '';
      display: none;
    }
  }
  .gform_body {
    hr {
      + h1, + h2, + h3, + h4, + h5, + h6 {
        margin-top: 0.325em;
      }
    }
    h1, h2, h3, h4, h5, h6 {
      font-weight: @headings-font-weight;
      //border-bottom  : 1px solid @brand-gray;
      font-size: 1.5em;

      + p {
        margin-top: .5em;
        margin-bottom: 1em;
      }
      &.gsection_title {
        display: none;
      }
    }
    //margin-left  : (@grid-gutter-width * -0.5);
    //margin-right : (@grid-gutter-width * -0.5);
    ul.gform_fields {
      margin-left: 0px;
      li.field_admin_only {
        display: none;

      }
      .gfield_list_container {
        max-width: 100%;
      }
    }
    ul.top_label {
      display: inline-block;
      //border  : 1px solid @brand-black;
      max-width: 100%;
      padding-bottom: 1em;
      margin-left: 0px;

      li {
        width: 100%;
        @media (min-width: @screen-sm-max) {
          &.half {
            width: 50%;
          }

          &.third {
            width: 33%;
          }

          p {
            font-family: @font-family-base;
            margin-top: .5em;
            margin-bottom: .5em;
          }
        }

        &.clear-div {
          display: inline-block;
          width: 100%;
          padding-bottom: 1em;
        }
        &.sm-clear-div {
          display: none !important;
          @media (max-width: @screen-sm-max) {
            display: inline-block !important;
            width: 100%;
            padding-bottom: 1em;
          }
        }
        display: inline-block;
        margin-top: 1em;
        &.gfield.gsection {
          margin-top: 0px;
          margin-bottom: .5em;
          float: left;
          width: 100%;
          display: inline-block;
          border-bottom: 0px;
          h2 {
            margin-left: (@grid-gutter-width * 0.5) !important;
            margin-right: (@grid-gutter-width * 0.5) !important;

          }
        }
        img.img-responsive {
          max-width: 100%;
          border-right: 1px solid #000;
          padding: 1em;
        }
        hr {
          margin-top: 1em;
          margin-bottom: 1em;
          padding-top: 0px;
          padding-bottom: 0px;
          padding-top: 0em;
          padding-bottom: 0em;
        }
        &.no-min-height {
        }
        &.gfield_html {
        }
        &.clear-left {
        }
        ul.gfield_checkbox {
          padding-left: 0px;
        }
        .ginput_container_checkbox {
          margin-top: .5em;
          li {
            margin-bottom: 0px;
          }
        }
        input, textarea, select {

          &.large {
            width: 100%;
          }
        }
        .ginput_container_number {
          width: 150px;
          &:after {
            display: inline-block;
            content: 'QTY';
            margin-left: 1em;
            font-size: 1em;
            font-weight: normal;
          }
          input {
            width: 50px;
          }
        }
        label.gfield_label + div.ginput_container {
        }
        label {
          font-family: @headings-font-family;
          //text-transform : uppercase;
          font-size: 1.25em;
          margin-bottom: .75em;
        }
        span label {
          font-size: 1em;
        }
        input {
          &:focus {
            outline: none;
            box-shadow: none;
            &::-webkit-input-placeholder {
              color: #aaa;
            }
            &::-moz-placeholder {
              color: #aaa
            }
            &:-moz-placeholder {
              color: #aaa
            }
            &:-ms-input-placeholder {
              color: #aaa
            }
          }
          &::-webkit-input-placeholder {
            color: #aaa;
          }
          &::-moz-placeholder {
            color: #aaa
          }
          &:-moz-placeholder {
            color: #aaa
          }
          &:-ms-input-placeholder {
            color: #aaa
          }
        }
        select {
          padding: .25em;
          font-size: 1em;
        }
        input[type="text"] {
          font-family: @font-family-base;
          font-weight: 400;
          font-style: normal;
          padding: .25em;
          font-size: 1em;
          &:focus {
          }
        }
        input[type="checkbox"], input[type="radio"] {
          margin-top: 0px;
          + label {
            margin-bottom: 0;
            font-size: .8em;
            margin-left: 1em;
            font-family: @font-family-base;
            font-weight: 400;

          }
        }
        ul.gfield_radio {
          li {
            padding-left: 0px;
            display: inline-flex;
            width: auto;
            float: none;
            align-items: center;
            margin-right: 0em;
            margin-bottom: 0em;
          }
        }
        input[type="checkbox"], input[type="radio"] {
        }
        textarea {
          font-family: @font-family-base;
          font-weight: 400;
          font-style: normal;
          padding: .25em;
          font-size: 1em;
        }
        .gfield_checkbox {
          li {
          }
        }
        span {
        }
        div.ginput_container {
        }
        &.hide-sub {
          span label {
            display: none;
          }
        }

        .gfield_html.gfield_no_follows_desc {
        }

        ul.gfield_radio li {
          @media (max-width: 768px) {
            width: 100% ;
          }
        }
        &.gfield_error {

          input {
          }
        }
      }
      .gfield_description {
        margin-top: .5em;
      }
      .gfield_description.validation_message {
        font-size: .75em;
        font-family: @headings-font-family;
        font-weight: @headings-font-weight;
        display: inline-block;
        .bg-primary();
        margin-top: .5em;
        padding: .125em .5em;
        width: auto;

      }

      .main-selection {

        display: inline-block;
        vertical-align: middle;
        position: relative;
        label {
          font-size: 2.5em;
          line-height: 1em;
          font-weight: 900;
          font-family: @headings-font-family;
          display: inline-block;
          vertical-align: middle;
          margin-right: .5em;
          margin-bottom: 0px;
          .gfield_required {
            display: none;
          }
        }
        select {
          font-size: 1.5em;
          vertical-align: middle;
        }
        .ginput_container_select {
          display: inline-block;
          margin-bottom: 10px;
        }
      }

    }
    .gform_hidden {
      label {
        display: none;
      }
    }
  }

  div.instruction.validation_message {
  }
  div.validation_error {
    font-size: 1.25em;
    font-family: @headings-font-family;
    font-weight: @headings-font-weight;
    .bg-primary();
    //text-transform : uppercase;
    padding: 1em;
    margin-bottom: 0em;
  }

  .btn.btn-default {
    margin-top: 0px;
  }
  .gform_title {
    margin-bottom: .5em;
  }
  .instruction.validation_message {
    font-size: .75em;
    margin-top: .35em;
    color: @brand-danger;
    display: none;
  }

  .gform_footer {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .ginput_complex {
    &.has_street {
      span {
        padding-rigth: 1em;
        width: 100%;
      }

      @media (min-width: @screen-sm-max) {
        span:nth-child(1) {
          width: 100%;
          padding-right: 0px;
          input {
            width: 100%;
          }
        }

        span:nth-child(2) {
          width: 100%;
          padding-right: 0px;
          input {
            width: 100%;
          }
        }

        span:nth-child(n+3) {
          width: 25%;
          input, select {
            width: 100%;
          }
        }

        span:last-child {
          padding-right: 0px;
        }
      }
    }
    span {
      width: auto;
      padding-right: 15px;
      padding-bottom: 15px;
      margin-right: 0px;
      margin-bottom: 0px;
      display: inline-block;
      label {
        font-weight: 400;
        width: auto;
        float: none;
        clear: both;
        display: block;
      }
      input, select {
        float: none;
      }
    }
  }

  .gform-body {
    > ul.gform_fields > li > label {
      font-size: 1.125em;
    }
  }
}

.gform_footer {
  input[type="submit"] {
    display: none;
  }
}

// COLORED FORM OPTION
#coloredForm .gform_wrapper {
  width: 100%;
  max-width: 100% !important;
  float: none;
  display: inline-block;
  margin-right: 0px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  .ginput_complex {
    label {
      display: none;
    }
    > span {
      margin-left: 0px !important;
      width: 100% !important;
      input {
        width: 100%;
      }
    }
    input {
      width: 100%;
    }
  }
  .ginput_complex input {
    width: 100%;
  }
  ul {
    border: 0px;
    padding-left: 0px;
    list-style: none;
    width: 100%;
    li {
      display: inline-block;
      float: left;

      margin-top: 0px;
      margin-bottom: 1em;
      box-sizing: border-box;
      clear: none;
      min-height: 3em;

      &.no-min-height {
        min-height: auto;
      }
      &.gfield_html {
        max-width: 100%;
        width: 100%;
      }
      &.clear-left {
        clear: left;
      }
      input {
        width: 100% !important;
      }
      label.gfield_label + div.ginput_container {
        margin: 0px;
        width: 100%;
        display: inline-block;
        vertical-align: middle;
      }
      label {
        font-family: @headings-font-family;
        text-transform: uppercase;
        display: block;
        margin: 0px !important;
      }
      input, select, textarea {
        width: 100%;
        margin-right: 0px;
        margin-top: 5px;
        vertical-align: baseline;
        &:focus {
          outline: none;
          background: @form-color;
          color: @form-background;
          box-shadow: none;
          &::-webkit-input-placeholder {
            color: @form-background;
          }
          &::-moz-placeholder {
            color: @form-background
          }
          &:-moz-placeholder {
            color: @form-background
          }
          &:-ms-input-placeholder {
            color: @form-background
          }
        }
        &::-webkit-input-placeholder {
          color: @brand-black;
        }
        &::-moz-placeholder {
          color: @brand-black
        }
        &:-moz-placeholder {
          color: @brand-black
        }
        &:-ms-input-placeholder {
          color: @brand-black
        }
      }
      //compile please
      textarea {
        height: 4em;
        max-height: 4em;
      }
      input[type="text"], select, textarea {
        border: 1px solid @form-border;
        background: @form-background;
        &:focus {
          background: @brand-black;
          border: 1px solid @form-border;
        }
      }
      input[type="checkbox"], input[type="radio"] {
        + label {
          display: inline-block;
          margin-left: 0px;
          width: auto;
          float: none;
          vertical-align: middle;
          width: 100%;
          position: relative;

        }
      }
      input[type="checkbox"], input[type="radio"] {
        float: left;
        width: auto;
        vertical-align: middle;
        margin-top: 0px !important;
      }
      textarea {
        width: 100%;
        margin-top: 5px;
      }
      .gfield_checkbox {
        width: 100%;
        li {
          width: 100%;
          vertical-align: middle;
          margin-bottom: 0px;
        }
      }
      span {
        margin-right: 0px;
        margin-left: 0px;
        padding-top: 0px !important;
        width: 100%;
      }
      div.ginput_container {
        margin-bottom: 0px;
      }
      &.hide-sub {
        span label {
          display: none;
        }
      }

      .gfield_html.gfield_no_follows_desc {
        margin: 0px 0px 0px 0px;
      }

      ul.gfield_radio li {
        float: left;
        align-items: center;
        vertical-align: middle;
        margin-bottom: 0px;
        width: 25%;
        margin-top: .25em;
        @media (max-width: 768px) {
          width: 100% ;
        }
      }
      &.gfield_error {
        input {
          border: 1px solid @brand-danger;
        }
      }
    }

    .gfield_description.validation_message {
      display: block;
      color: @brand-black;
    }

  }
  div.instruction.validation_message {
    display: none;
  }
  div.validation_error {
    margin-top: 0em;
    margin-bottom: 0em;
    padding: 0em;
  }
  .btn.btn-primary {
  }
  .gform_footer {
    padding: 0em !important;
    margin: 0em !important;
  }

}

.border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
}

////////////// GATED DOWNLOAD

.gated-download {
  margin-top: 1em;
  .btn-col {
    text-align: center !important;
    .btn {
      margin-top: 0px;
    }
  }
  .bg-gray();
  .border-box();
  padding: (@grid-gutter-width * 0.5);
  form {
    .border-box();
    display: inline-block;
    padding: 0px !important;
  }
  .gform_wrapper {
    .border-box();
    margin: 0px 0px 0px 0px;
    width: 100%;
    padding: 0px !important;
    .gfield_required {
      color: @brand-green;
    }
    .gform_body, .gform_heading {
      .border-box();
      margin: 0px 0px 0px 0px;
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;

    }
    .gform_heading {
      padding: 0 (@grid-gutter-width * 0.5);
      margin-top: 20px;
      .gform_description {
        margin-bottom: 5px;
        padding-bottom: 15px;
        border-bottom: 1px solid @brand-gray-light;
        display: inline-block;
        width: 100%;
        margin-top: 5px;
      }

    }
    .gform_body > ul {
      width: 100%;
    }
  }
  ul.gform_fields {
    padding-bottom: 0px;
    width: 100%;
    li {
      &:before {
        display: none;
      }
      padding-left: (@grid-gutter-width * 0.5) !important;
      padding-right: (@grid-gutter-width * 0.5) !important;
      input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
        color: inherit;
        font-family: @font-family-base;
        font-weight: normal;
        background: @brand-gray-dark;
        border-bottom: 1px solid @brand-green;
        border-left-width: 0px;
        border-top-width: 0px;
        border-right-width: 0px;
      }
    }

  }

  .gform_wrapper .gform_footer {
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;

  }
}

.page-template-page-sidebar article #content ul.gform_fields {
  margin-left: 0px;
}

.PreviousEmployers .gfield_list_container {
  max-width: 100%;
  width: 100%;
  display: inline-block;
  thead {
    display: none;
  }
  tbody tr {
    display: inline-block;
    padding:10px 0px;
    border-bottom:1px solid @brand-gray-dark;
    &:last-child {border-bottom:0px;}
    td {
      display: inline-block;
      width: 20%;
      padding: 5px;
      box-sizing: border-box;
      height:60px;
      input {
        width:100%;
      }
      @media (max-width: @screen-md-min) {
        width: 100%;
      }
      &:before {
        content: attr(data-label);
        display: inline-block;
        position: relative;
        font-weight: 900;
        font-size: 1em;
        font-family: @headings-font-family;

        width: 100%;
      }
      &.gfield_list_icons {
        height:60px;
        vertical-align: middle;

        &:last-child {
          vertical-align:middle;
          img {
            margin-top:22px;
          }
          &:before {
            margin-top:22px;
            content: 'Add Another';
            display: inline-block;
            position: relative;
            font-weight: 900;
            vertical-align:middle;
            font-size: .75em;
            font-family: @headings-font-family;

            width: auto;
          }
        }

      }
    }
  }
}

