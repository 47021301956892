
.page-template-page-sidebar {

  #content {
    #article-content{
      ul a {
        font-size       : 1.125em;
        color           : @text-color;
        padding-bottom  : .25em;
        padding-top     : .25em;
        text-decoration : underline;
        &:hover {
          color           : @brand-primary;
          text-decoration : underline;
          }
        }

      a {
        //color           : @brand-black;
        padding-bottom  : .25em;
        padding-top     : .25em;
        text-decoration : underline;

        &:hover {
          &.btn {
            text-decoration: none;
          }
          text-decoration : underline;
        }
        &.btn {
          text-decoration: none;
        }
      }
      img.alignnone {
        margin-top:@image-offset;
        margin-bottom:@image-offset;
      }

      }
    }
  }


#sidebar {
  font-size: 14px;
  padding-bottom:1em;

  aside {border-left:1px solid @text-color; width:100%;}
  ul li {
    padding:0px;
    &:before {
      display: none;

    }
  }
  a {
    text-decoration : none;
    }
  .widget_nav_menu {
    padding-top:0px;
    padding-bottom:0px;
    }
  h4 {
    padding: .25em .25em;
    border-bottom: 1px solid @text-color;
    margin-bottom:0px;
    }
  ul {
    list-style:none;
    //text-transform: uppercase;
    font-family: @headings-font-family;
    padding-left:0px;
    margin-left:0px;
    margin-bottom:0px;
    font-size: 1.125em;
    span.post-date {
      color: ligthen(@brand-gray-dark,20%);
      font-size:.8em;
    }
    li {
      border-bottom: 1px solid @text-color;
      padding: .125em .5em;
      ul.children {
        margin-top:0em;
        font-size: .9em;
        li {
          .arrowed-ul-before();
          padding-left: 1em;
          border-bottom: 0px;
          &:before {
            margin-top:.75em;
          }
        }
      }
      a {
        text-decoration:none ;
        color: @text-color;
        padding:0px;
        width:100%; display:inline-block;

        &:hover { color: @brand-primary;}
        }

      }
    }
  section > div > ul.menu > li > a {
    font-weight: 600;
    }

  .cta-widget {
    border-top:1px solid @brand-black;
    border-right: 1px solid @brand-black;
    border-bottom: 1px solid @brand-black;
    padding:1.5em ;
    h4 {
      padding: 0;
      max-width:75%;
      margin-top:.5em;
      border-bottom:0px;
      }
    .btn {
      margin-bottom:.5em;
      }
    }
  }





/* 3RD TIER PAGE TEMPLATE */

.page-template-page-sidebar article #content ul {
  margin-left : 10px;
}

.page-template-page-sidebar article #content h3,
.page-template-page-sidebar article #content h4 {
  clear : left;
}



.page-template-page-sidebar article #content .bg-cover {
  -webkit-background-size : cover !important;
  background-size         : cover !important;
  background-position     : center center !important;
  background-repeat       : no-repeat !important;
  background-attachment   : scroll !important;
}

/* TESTIMONIALS */

.testimonials-page .border-all strong {
  display    : block;
  clear      : both;
  margin-top : .75em;
}


#searchform {
  display:table-row;
  display:flex;
  align-items: center;
  padding: 0px 5px 0px 0px;
  input {
    width:100%;
  }
  #searchsubmit {

    &:after {
      display:none;
    }
  margin-top:0px;
}
}

#sidebar {
  @media(max-width: @screen-sm-min) {
    aside {
      margin-top:30px;
      padding-top:30px;
      border-top:1px dotted @text-color;
      border-left: 0px solid;
    }
  }
}