
//
//  // HUB SECTIONS ------------------------------------------------------------------------------------------------------------------------------
//// HUB SECTIONS ------------------------------------------------------------------------------------------------------------------------------
//// HUB SECTIONS ------------------------------------------------------------------------------------------------------------------------------
//.hub-2-1, .hub-1-2, .hub-1-2-alt {
//  // Describes A hub Section which is a section with box blocks with imagea and small screen size dual column layout
//  // variants of this are not included.
//  @media (min-width: @screen-sm-min) {
//    .row {
//      .column {
//        padding-left: 0px;
//        padding-right: 0px;
//        margin-right: (@grid-gutter-width * .5);
//      }
//      .column + .column {
//        margin-right: 0;
//      }
//    }
//  }
//
//  @media (max-width: @screen-xs-max) {
//    .column {
//      padding-left: 0px;
//      padding-right: 0px;
//      margin-right: @grid-gutter-width;
//      margin-left: @grid-gutter-width;
//      + .column {
//        margin-top: (@grid-gutter-width * .5);
//      }
//    }
//  }
//
//  @media (min-width: @screen-sm-min) {        .row { display:flex; align-items: stretch; } }
//  //@media (min-width: @screen-md-min) {        .row { display:flex; align-items: stretch; } }
//}
//
//
//.textIcon-1-1-1 {
//  // Describes a section with 3 icons in 3 columns and a transparent or colored background
//  // variants of this are not included.
//  .container{
//    .row {
//      .column {
//
//        @media(max-width:@screen-sm-max) {
//          text-align:center;
//          padding-top:@sfix-pad;
//          padding-bottom:@sfix-pad;
//          h1,h2,h3,h4,h5 {  text-align:center;}
//          }
//        }
//      }
//    }
//  }
//
//.textList {
//  // Describes a section with a text column a list column.
//  // variants of this are not included.
//  .container{
//    .row {
//      .column {
//        &.image {
//          .text-center();
//          .no-pad-vert();
//          .sm-pad-horz();
//          }
//        &.text-col{
//          .lg-pad-vert();
//          }
//        &.list-col{
//          .lg-pad();
//          .text-center();
//          }
//        @media(max-width:@screen-sm-max){
//          &.text-col{
//            padding:25px;
//            }
//          &.list-col{
//            padding:25px;
//            div {
//              padding:0px !important;
//              }
//            }
//          }
//        }
//      }
//    }
//  }
//
//.serviceFeature {
//  // Describes a section with a large image and a box with text title and button.
//  // variants of this are described in sf-1 - sf-3
//  .text-large();
//  &.sf-1,&.sf-2,&.sf-3,&.sf-3-r,&.sf-4,&.sf-6 {
//    @media(max-width: @screen-sm-max) {
//      .row {
//        .column {
//          &.image {
//            .pt-image { img { max-height:300px;} }
//            }
//          }
//        }
//
//      }
//    }
//  &.sf-6{ font-size:1em ;
//
//    .row {
//      .column {
//
//        &.image {
//          position:relative;
//          display:inline-block;
//          .pt-image { display:inline-block; vertical-align : bottom; img { max-height:400px; display:inline-block; vertical-align : bottom;
//
//            }
//            @media(min-width:@screen-md-min){
//              padding-top:50px;
//              }
//            }
//          }
//        }
//      }
//
//    }
//
//  &.sf-1,&.sf-2 {
//    @media(min-width: @screen-xs-max) and (max-width: @screen-sm-max) {
//    .row {
//      display: flex;
//      align-items: center;
//      .column {
//
//          &.text-col {
//            width:60%;
//            float:left;
//            display:block;
//            }
//          &.image {
//            width: 40%;
//            text-align:center !important;
//            float:left;
//            display:block;
//            div {
//              &.xl-pad, &.sm-pad, &.md-pad { padding: 10px 10px 10px 10px;}
//              }
//            //.pt-image.no-pad-vert img { margin-bottom: (-3 * @sfix-pad); }
//          }
//          }
//        }
//      }
//    }
//  &.sf-1 {
//    .no-pad-vert();
//    }
//  &.sf-2  {}
//  &.sf-3  {
//    .bg-half-image-right();
//    // Needs Other color replacement bg
//    .bg-white();
//    }
//  &.sf-3-r{
//    .bg-half-image-left();
//    // needs other replacement color
//    .bg-white();
//    }
//  &.sf-3-r,&.sf-3 {
//    .container {
//      @media(max-width: @screen-sm-max) {
//        padding-top:0px;
//        padding-bottom:0px;
//        }
//      .row {
//        @media(max-width: @screen-sm-max) {
//          padding-left:@sfix-pad;
//          padding-right:@sfix-pad;
//          }
//        .column {
//          &.image {
//            .no-pad-vert();
//            .lg-pad-horz();
//            text-align:center;
//            }
//          }
//        }
//      }
//    @media(min-width: @screen-xs-max) and (max-width: @screen-sm-max) {
//      background-size:cover;
//      .row {
//        display: flex;
//        align-items: center;
//        .column {
//          &.text-col {
//            width:60%;
//            float:left;
//            display:block;
//            &:after{
//              display:none !important;
//              }
//            }
//          &.image {
//            width: 40%;
//            text-align:center !important;
//            float:left;
//            display:block;
//            div {
//              &.xl-pad, &.sm-pad, &.md-pad { padding: 10px 10px 10px 10px;}
//              }
//            }
//          }
//        }
//      }
//    }
//  &.sf-4 {
//    .bg-cover();
//    .column {
//      &.text-col{}
//      &.list-col{}
//      }
//    }
//    // Describes a section with a large image and a leading a border box div with link.
//    // variants of this are not included.
//  .container {
//    @media(max-width: @screen-sm-max) {
//      padding-top:@sfix-pad;
//      padding-bottom:@sfix-pad;
//      .column + .column {
//        &.image {
//          margin-bottom: ( -1 * @sfix-pad);
//          }
//        }
//      }
//    .row {
//      @media(max-width: @screen-sm-max) {
//        padding-left:@sfix-pad;
//        padding-right:@sfix-pad;
//        }
//      .column {
//        &.image {
//          .no-pad-vert();
//          .lg-pad-horz();
//          text-align:center;
//          }
//
//        @media(max-width:@screen-sm-max){
//          &.list-col{
//            padding:25px !important;
//            div {
//              padding:0px !important;
//              }
//            }
//        }
//        }
//      }
//    }
//  }
//
//.callToAction {
//  // Describes a section CTA with text column and button and possibly image column .
//  // variants of this are not included.
//  h1,h2,h3,h4,h5,h6,a { margin-bottom:0px; margin-top:0px;}
//  &.cta-logo {
//    @media ( min-width : @screen-xs-max) and (max-width : @screen-sm-max) {
//      .md-row-center {
//        display     : block;
//        }
//      }
//    .row{
//      .text-large();
//      @media(max-width: @screen-sm-max) {
//        font-size:1em;
//        .md-pad-vert();
//        .column {
//
//          &.image {width:25%;}
//          &.text-col {width:75%; margin-top:1em;}
//          &.btn-col {width:75%; text-align:left; padding-top:1em;}
//          }
//        }
//      @media(max-width: @screen-xs-max) {
//        text-align : center;
//        font-size  : 1em;
//        .column.image {
//          max-width : 200px;
//          width:100%;
//          clear     : both;
//          display   : inline-block;
//          }
//
//        .column {
//          &.text-col { width : 100%; margin-top:0; }
//          &.btn-col { width : 100%; text-align:center; }
//          }
//        }
//      }
//    }
//  > .container > .row {
//    @media(min-width:@screen-sm-max){
//    padding-top    : (1.125 * @grid-gutter-width);
//    padding-bottom : (1.125 * @grid-gutter-width);
//      }
//    padding-top    : (1 * @grid-gutter-width);
//    padding-bottom : (1  * @grid-gutter-width);
//    }
//
//  @media ( min-width : @screen-xs-max) and (max-width : @screen-sm-max) {
//    .md-row-center {
//      display     : flex;
//      align-items : center;
//      flex-wrap   : nowrap;
//      .text {
//        flex-grow: 3;
//        }
//      }
//    }
//  .column {
//    @media (max-width : @screen-xs-max) {
//      max-width     : 80%;
//      margin        : auto auto;
//      text-align    : center;
//      margin-bottom : 1em;
//      padding-top:0px;
//      padding-bottom:0px;
//      &.image {
//        max-width     : 160px;
//        margin        : auto auto;
//        margin-bottom : 1em;
//
//        }
//      }
//    }
//
//  .column {
//    &.image {}
//    &.text-col {}
//    &.btn-col { text-align: center;}
//    }
//  }
//
//
//
//.bigCta {
//  // Describes a section CTA with text column and button.
//  // variants of this are not included.
//  .container{
//    .row {
//      &.getting-started {
//        @media(max-width: @screen-sm-max) {
//          text-align : center;
//          .column {
//            @media(max-width: @screen-sm-max) and (min-width: @screen-xs-max) {
//            min-height: 180px;
//            &:nth-child(3),&:nth-child(4) { border-top: 1px solid #000;}
//            &:nth-child(1),&:nth-child(3) { border-right: 1px solid #000; border-bottom:0px;}
//              }
//            h1,h2,h3,h4,h5,h6,p,a,div {
//              text-align : center !important;
//              }
//            }
//          }
//        }
//      &.hr-row {}
//      &.cta-row{
//        @media(max-width: @screen-sm-max) {
//          font-size:1em;
//          .column {
//
//            &.image {width:25%;}
//            &.text-col {width:75%; margin-top:1em;}
//            &.btn-col {width:75%;}
//            }
//          }
//        @media(max-width: @screen-xs-max) {
//          text-align : center;
//          font-size  : 1em;
//          .column.image {
//            max-width : 200px;
//            width:100%;
//            clear     : both;
//            display   : inline-block;
//            }
//
//          .column {
//            &.text-col { width : 100%; margin-top:0; }
//            &.btn-col { width : 100%; }
//            }
//          }
//        }
//
//
//      .column {
//        // only applicable to cta-row
//        &.image {}
//        &.text-col {}
//        &.btn-col {}
//        }
//      }
//    }
//  }
//
//
//.textIcon .row {
//  &.icon-row{
//    .md-row-stretch();
//    .sm-pad-vert();
//    .column {
//      &.icon-col{
//        .md-pad-horz();
//        .text-right();
//        @media(max-width: @screen-sm-max) {
//          clear:left;
//          float:left;
//          margin-bottom:1em;
//          padding-right:1em;
//          }
//        }
//      &.text-col{
//        @media(max-width: @screen-sm-max) {
//          padding-right:3em;
//          margin-bottom:1em;
//
//          }
//
//        }
//      }
//    }
//  .column { }
//  }
//
//.textIcon-cta {
//  // Describes a section with a leading icon column icon column followed by a bar split CTA
//  // variants of this are not included.
//  .bg-black(); .bg-full-overlay-dark(); .md-pad-vert(); .bg-fixed(); .bg-cover();
//  .container{
//    .row {
//      &.icon-row{
//        .md-row-stretch();
//        .sm-pad-vert();
//        .column {
//          &.icon-col{
//            .md-pad-horz();
//            .text-right();
//            @media(max-width: @screen-sm-max) {
//              clear:left;
//              float:left;
//              margin-bottom:1em;
//              padding-right:1em;
//              }
//            }
//          &.text-col{
//            @media(max-width: @screen-sm-max) {
//                padding-right:3em;
//              margin-bottom:1em;
//
//              }
//
//            }
//          }
//        }
//      &.hr-row{}
//      &.cta-row{
//        .sm-pad-vert();
//        .md-row-center();
//        .column {
//          &.text-col {
//            @media( max-width: @screen-sm-max) {
//              text-align:center;
//
//              h1,h2,h3,h4,h5 { text-align:center;}
//              padding-bottom:1em;
//              }
//            }
//          }
//        }
//      .column { }
//      }
//    }
//  }
//.text-IconMixin(@width, @height){
//  @media(max-width: @screen-sm-max) and (min-width: @screen-xs-max){
//    .column {
//      width: @width;
//      float:left;
//      border-bottom: 0px;
//      border-right:0px;
//      border-left:0px;
//      min-height: @height;
//      text-align:center;
//      h1,h2,h3,h4,h5 { text-align:center;}
//      }
//
//    }
//  @media(max-width: @screen-xs-max){
//    .column {
//      text-align:center;
//      h1,h2,h3,h4,h5 { text-align:center;}
//      }
//    }
//  }
//.textIcon-6 {
//  // Describes a section with 6 icon columns with heading below.
//  // variants of this are not included.
//  .xs-pad-vert();
//  .text-small();
//  .text-center();
//
//  .container{
//    .row {
//      .text-IconMixin(33%, 115px);
//      }
//    }
//  }
//.textIcon-4 {
//  // Describes a section with 4 icon columns with heading below.
//  // variants of this are not included.
//  .xs-pad-vert();
//  .text-small();
//
//  .container{
//    .row {
//      .text-IconMixin(50%,175px);
//      }
//    }
//  }
//.textIcon-3 {
//  .container{
//    .row {
//      .text-IconMixin(33%,220px);
//      }
//    }
//  }
//.textIcon-6,.textIcon-4,.textIcon-3{}
//
//  .basic-Section {
//  // Describes a section with 3 icons in 3 columns and a transparent or colored background
//  // variants of this are not included. asdf
//  .container{
//    .row {
//      .column {
//
//        }
//      }
//    }
//  }
//
//.textImgBar-1-1 {
//  // Describes a section with 3 icons in 3 columns and a transparent or colored background
//  // variants of this are not included.
//  .container{
//    @media(max-width: @screen-sm-max) {
//      .row {
//        .column {
//          &.image {
//            .pt-image { img { max-height:300px;} }
//            }
//          }
//        }
//      }
//    .row {
//
//      .column {
//        &.image {
//          .text-center();
//          .sm-pad-vert();
//          .md-pad-horz();
//          }
//        &.text-col{
//
//          }
//        }
//
//      }
//    }
//  @media(min-width: @screen-xs-max) and (max-width: @screen-sm-max) {
//    .row {
//      display: flex;
//      align-items: center;
//      .column {
//        &.text-col {
//          width:60%;
//          float:left;
//          display:block;
//          }
//        &.image {
//          width: 40%;
//          text-align:center !important;
//          float:left;
//          display:block;
//          div {
//            &.xl-pad, &.sm-pad, &.md-pad { padding: 10px 10px 10px 10px;}
//            }
//          }
//        }
//      }
//    }
//  }
//
//.one-threeIcons {
//  .bg-black();
//  .bg-full-overlay-dark();
//  .md-pad-vert();
//  .bg-fixed();
//  .bg-cover();
//  // Describes a section with 3 icons in 3 columns and a transparent or colored background
//  // variants of this are not included.
//  .container{
//
//    .row {
//      .column {
//        @media (max-width : @screen-sm-max) {
//          border-right:0px;
//        &:first-child div {
//            p, h2 {
//              text-align : center !important;
//              }
//            }
//          }
//        }
//      }
//    }
//  }
//
//.basic-Section {
//  // Describes a section with 3 icons in 3 columns and a transparent or colored background
//  // variants of this are not included.
//  .container{
//    .row {
//      .column {
//
//        }
//      }
//    }
//  }
//
//
//
//.basic-Section {
//  // Describes a section with 3 icons in 3 columns and a transparent or colored background
//  // variants of this are not included.
//  .container{
//    .row {
//      .column {
//
//        }
//      }
//    }
//  }
//.promoHeading {
//  .bg-yellow {
//    a {
//      color: #000;
//      }
//    }
//  }
//.ySummary.bg-yellow {
//  a {
//    color: #000;
//    }
//  }
//
//
//
//.column {
//  @media(max-width: @screen-sm-max) {
//    &.border-right {
//      border-bottom: 1px solid #ccc;
//      &:nth-child(2),&:nth-child(4) { border-right:0px;}
//      &:nth-child(3),&:last-child{ border-bottom:0px;}
//      }
//    &.border-left {
//      border-top: 1px solid #ccc;
//      &:nth-child(1),&:nth-child(3) { border-left:0px;}
//      &:last-child{ border-top:0px;}
//      }
//    }
//
//  }
//
//
//.bg-yellow .column {
//  @media(max-width: @screen-sm-max) {
//    &.border-right {
//      border-bottom: 1px solid #000;
//      border-right:0px;
//      }
//    &.border-left {
//      border-top: 1px solid #000;
//      border-left:0px;
//      }
//    }
//
//  }
//
//
//
//
//
//
//.ySummary.bg-yellow , .promoHeading .row.bg-yellow {
//  .column {
//    @media(max-width: @screen-sm-max) {
//      min-height : 200px;
//      text-align : center;
//      &:nth-child(2) {
//        border-right : 0px;
//        }
//
//      &:nth-child(1), &:nth-child(2) {
//        border-bottom : 1px solid #000;
//        }
//
//      &:nth-child(1), &:nth-child(3) {
//        border-right : 1px solid #000;
//        }
//
//      &:nth-child(3), &:nth-child(4) {
//        border-bottom : 0px;
//        }
//      }
//
//      @media(max-width: @screen-xs-max) {
//        min-height : auto;
//        border-bottom: 1px solid #000 !important;
//        border-right:0px;
//        &:last-child {
//          border-bottom:0px !important;
//          }
//        }
//      }
//
//
//  &.ySum3 {
//    .column {
//      @media(max-width: @screen-sm-max) and (min-width:@screen-xs-max) {
//        min-height: 200px;
//        text-align:center;
//        width:33%;
//        border-bottom:0px;
//        border-right:1px solid #000;
//        &:last-child{ border-right: 0px;}
//
//        }
//      }
//    }
//  }
//
//
//.HO-PC {
//  // Describes a section with 3 icons in 3 columns and a transparent or colored background
//  // variants of this are not included.
//  .bg-full-overlay-dark();
//
//  &:before { background: rgba(0,0,0,.25); }
//  .container{
//    .row {
//
//      .column {
//        @media( max-width: @screen-sm-max) {
//          &.border-right {
//            border-right:0px;
//            }
//          }
//        &.img-col{
//          div + div { padding:1em; }
//
//          @media( max-width: @screen-sm-max) {
//            .pt-image { img { max-height:300px; } }
//
//
//            }
//          }
//        &.text-col{ }
//
//        }
//      }
//    }
//  }
//
//
//
//.border-left-md,
//.border-left-lg,
//.border-left-xl
//{
//  @media (max-width:@screen-sm-max){
//    border-top:.75em solid @border-color;
//    border-left:0px;
//    }
//  }
//
//
//
//section.promoHeading {
//  @media (max-width:@screen-sm-max){
//    padding-top:90px;
//    }
//  }

.text-icon-cols {
  .pad-sm-vert();
  .bg-cover();
  .bg-image-dark();
  .text-col {
    .pad-xs-vert();

  }
  .icon-col{
    .pad-xs-vert();
  }
}

.text-img-cols {
  .img-col{

      display:flex;
      align-items: flex-end;

  }
  .text-col{

      display:flex;
      align-items: center;

  }
}

.split-cta {

  .text-col {
    .border-right();

    .pad-xs-vert();
  }
  @media(min-width: @screen-md-min) {
    .text-col {
      .pad-sm-horz();
    }

    .text-col:first-child {
      padding-left: (@grid-gutter-width /2)
    }

    .text-col:last-child {
      border-right: 0px;
      padding-right: (@grid-gutter-width /2)
    }
  }
  @media( max-width: @screen-md-min) {
    padding-top:30px;
    padding-bottom: 30px;
    .text-col {
      padding-top: 30px;
      padding-bottom: 30px;
      border-bottom: 0px;
      border-top: 1px solid;
    }
    .row:first-child .text-col:first-child {
      border-top: 0px;
    }
    .hr-row {
      display: none;
    }
    .text-col:empty {
      display: none;
    }

  }


}
.split-cta.bg-primary {
  .container {
    .row:first-child {
      .column.col-md-12 .text-3x {

        @media(max-width: @screen-md-min){
          font-size: 2em;
          margin-top: 15px;
        }
      }
    }
  }
}

.icon-text-row {

  display: flex;
  align-items: center;

  .icon-col {
    text-align: right;
    width: 12.5%;
    padding-right: 0px;
  }
  .text-col {
    width: 87.5%;
    text-align: left;
    padding-right: 60px;
  }
  @media(max-width: @screen-sm-max) {
    border-top: 1px solid;
    padding-top: 30px;
    font-size: 1.125em;
    .icon-col {
      width: auto;
      padding-right: 0px;
    }
    .text-col {

      padding-right: 15px;
    }
  }

}

.leading-row {
  .row {
    @media (min-width: @screen-xs-max) and (max-width: @screen-md-min) {
      display: flex;
      align-items: center;

      .img-col {
        width: 33%;
        img {
          display: inline-block;
          max-width: 100%;
        }
      }

      .infg-col {
        width: 33%;
      }

      .text-col {
        width: 66%;
        &:first-child {
          width: 50%;
          + .img-col, + .infg-col {
            width: 50%;
          }
        }
      }
    }
  }
  .row {
    @media (max-width: @screen-xs-max) {
      &.spcial-row {
        .img-col {
          text-align:center;
          padding-top:15px !important;
          padding-bottom :15px !important;
          border-bottom: 1px solid @text-color;
          &:last-child {
            border-bottom: 0px;
          }
        }
      }
      .column {
        &:first-child {
          padding-bottom: 30px;
          + .column{
            padding-top: 0px;
          }
        }

      }
      .img-col {
        text-align: left;
        img {
          display: inline-block;
          max-width: 320px;
        }
      }
    }

  }

}


@media(max-width: @screen-md-min) {
  .hr-row {
    display: none;
  }
  .split-cta.link-grid {

  }


  .border-right-fix {
    .border-right {
      padding-bottom: 30px;
      + .text-col {
        @media(max-width: @screen-sm-min){
          padding-top:30px;
        }
      }
      ~ .text-col {
        padding-top: 15px;


        padding-bottom: 15px;
        &:last-child {
          @media(max-width: @screen-sm-min) {
            padding-bottom: 30px;
          }
        }
      }
    }
  }

}

.img-section {
  padding-bottom: 60px;
  @media(max-width: @screen-md-max) and (min-width: @screen-sm-min){
    padding-top:30px; padding-bottom:30px;
    .hr-row {
      display: none;
    }
    .leading-row {


    }
    .row {
      margin-bottom: 1em;
      padding-bottom:1em;
      border-bottom: 1px solid @text-color;
      &:last-child {
        border-bottom:0px;
      }
    }
    .row .column.text-col {
      padding:15px 15px 15px 15px;

    }
    .column {
      border-right: 1px solid @text-color;
      border-bottom: 0px solid;
      &:last-child {
        border-right: 0px;
      }
    }
  }

  @media(max-width: @screen-sm-min) and (min-width: @screen-xs-min){
    padding-top:15px; padding-bottom:15px;
    .hr-row {
      display: none;
    }
    .leading-row {
      border-bottom: 1px solid @text-color;
      .column {
        display: block;
      }
    }
    .row {
      margin-bottom: 0em;
      padding-bottom:0em;
      border-bottom: 0px solid @text-color;
      &:last-child {
        border-bottom:0px;
      }
    }
    .row .column.text-col {
      padding:30px 25px 30px 25px;

    }
    .column {
      display: flex; align-items: center;
      .pt-image {
        width: 25%;
        padding-right:15px;
        + div {
          width: 75%;
        }
      }
      border-right: 0px solid @text-color;
      border-bottom: 1px solid @text-color;
    }
    .row:last-child {
      .column:last-child {

          border-bottom: 0px;


      }
    }
  }
  @media(max-width: @screen-xs-min){
    padding-top:15px; padding-bottom:15px;
    .hr-row {
      display: none;
    }
    .leading-row {
      border-bottom: 1px solid @text-color;
      .column {
        display: block;
      }
    }
    .row {
      margin-bottom: 0em;
      padding-bottom:0em;
      border-bottom: 0px solid @text-color;
      &:last-child {
        border-bottom:0px;
      }
    }
    .row .column.text-col {
      padding:30px 25px 30px 25px;

    }
    .column {
      border-right: 0px solid @text-color;
      border-bottom: 1px solid @text-color;
    }
    .row:last-child {
      .column:last-child {
        border-bottom: 0px;
      }
    }
  }

}

.industry-solutions {

  @media(max-width: @screen-sm-min){
    .sm-pad-vert {
      padding-top:15px;
      padding-bottom:15px;
    }
  }
}


.category-template, .archive-downloads {
  article header h2 {
    //color: @brand-primary;
  }
  article {
    width: 100%;
  }
}