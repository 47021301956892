.social-links {
  text-align:right;
  font-size: 1em;
  @media( max-width:@screen-sm-max) { text-align:left; margin-top:1em;}
  @media( max-width:@screen-xs-max) { text-align:center; margin-top:1em;}
  .social-link {
    font-size:1em;
    font-size: 16px;
    height: 32px;
    width: 24px;
    padding: .5em .25em;
    line-height:1em;
    text-align:center;
    display:inline-block;
    margin-left:.25em;
    margin-right:.125em;
    
    background: @footer-text;
    color: @footer-color-1;
    position:relative;
    &:before,&:after {
      content:'';
      display:inline-block;
      width:0em;
      border-color: @brand-green;
      position:absolute;
    }
    &:before {
      top: 0;
      left: -.5em;
      border-left: solid .5em transparent;
      border-bottom: solid 2em @footer-text;
      border-top: solid 0em transparent;
      border-right: 0em solid transparent;
      border-right:0em ;


    }
    &:after {
      top: 0;
      right: -.5em;
      border-right: solid .5em transparent;
      border-top: solid 2em @footer-text;
      border-bottom: solid 0em transparent;
      border-left: 0em solid transparent;

    }
    i {
      font-size:1em;
      width:1em;
      height:1em;
    }
    &:hover{
      background : @brand-green;
      border-left-color: @brand-green;
      border-right-color: @brand-green;
      &:before {
        border-bottom-color: @brand-green;
      }
      &:after {
        border-top-color:@brand-green;
      }
      i {
        color: @footer-color-1;
      }
    }
  }
}