.history-page {.pt-image {
  width:100%;
  }
  }

.pt-image {
  display:inline-block;
  width:auto;
  max-width:100%;
  img {
    max-width:100%;
    }
  &.row {
    display:block;
    width:100%;
    text-align:center;
    }
  &.full-width {
    display:block; width:100%;
    text-align:center;
    img {
      display:inline-block;
      float:none;
      max-width:100%;
    }
  }
}

img.alignright {
  float:right;
  margin-left:@image-offset ;
  margin-bottom:@image-offset ;
  margin-top:@image-offset ;

  display:inline-block;
}
img.alignleft {
  float:right;

  margin-right:@image-offset ;
  margin-bottom:@image-offset ;
  margin-top:@image-offset ;
  display:inline-block;
}
@media(max-width:@screen-md-min) {
  img.alignleft,img.alignright {
    &.size-full {
      float:none;
      display:block;
      }
    }
  }

@media(max-width:@screen-sm-min) {
  img.alignleft,img.alignright {
    display:block;
    float: none;
    margin-top:1em;
    margin-bottom:1em;
    max-width:100%;
    height:auto;
  }
}

img.aligncenter {
  display: block;

  margin-bottom:@image-offset ;
  margin-top:@image-offset ;
  margin-right: auto;
  margin-left: auto;
}



img.text-center { display: inline-block; margin:auto auto; }

.column.text-col {
  div.pt-image ~ div {
    margin-top:1em;
  }
}