.headings-iterator(@weight,@n, @i: 1) when (@i =< @n) {
  h@{i} {
    font-weight: @weight;
  }
  .headings-iterator(@weight, @n, (@i + 1));
}

// Text Modifier Classes.
.weight-100 {
  .headings-iterator('100', 6);
}

.weight-300 {
  .headings-iterator('300', 6);
}

.weight-500 {
  .headings-iterator('500', 6);
}

.weight-900 {
  .headings-iterator('900', 6);
}

.text-xs {
  font-size: @text-xs;
  line-height: (@line-height-base);
}

.text-sm {
  font-size: @text-small;
  line-height: (@line-height-base);
}

.text-md {
  font-size: @text-reg;
  line-height: (@line-height-base);
}

.text-lg {
  font-size: @text-large;
  line-height: (@line-height-base);
  @media (max-width: @screen-xs-max) {
    font-size: 1em;
  }
  @media (max-width: @screen-sm-max) {
    font-size: 1em;
  }
  @media (max-width: @screen-md-max) {
    font-size: 1em;
  }

}

.text-xl {
  font-size: @text-xlarge;
  line-height: (@line-height-base);
  @media (max-width: @screen-xs-max) {
    font-size: 1em;
  }
  @media (max-width: @screen-sm-max) {
    font-size: 1em;
  }
  @media (max-width: @screen-md-max) {
    font-size: 1em;
  }
}

.size-iterator(@n, @i: 2) when (@i =< @n) {
  .text-@{i}x {
    font-size: unit(@i, em);
  }
  .size-iterator(@n, (@i + 1));
}

.size-iterator(5);

.lightWeight {
  font-weight: 100;
}

.pumpSubhead {
  font-size: 2em;
  width: 100%;
  display: inline-block;
  @media (max-width: @screen-xs-max) {
    font-size: 1.15em;
  }
  @media (max-width: @screen-sm-max) {
    font-size: 1.35em;
  }
  @media (max-width: @screen-md-max) {
    font-size: 1.55em;
  }
}

//fix all paragraphs
p:last-child {
  margin-bottom: 0px;
}

p > .btn {
  margin-top: (@grid-gutter-width /3.5);
  clear: both;
  display: inline-block;
}

.btn {
  margin-top: (@grid-gutter-width /3.5);
  font-weight: @btn-font-weight;
}

h1, h2, h3, h4, h5, h6 {
  clear: both;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6, .btn {
  text-transform: none;
  letter-spacing: @letter-spacing-correction;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-left: @tex-indent-correction
}
h1, .h1 {
  // text-transform: uppercase;
}
strong {
  font-weight: 700;
}


p, ul {
  + h1,
  + h2,
  + h3,
  + h4,
  + h5,
  + h6 {
    margin-top: 1em;
  }
}
div {
  h1, h2, h3, h4, h5, h6, p  {
    &:last-child {
      margin-bottom: 0px;
      &:first-child {
        margin-bottom: .25em;
      }
    }

  }
}
.live-area section {  h1, h2, h3, h4, h5, h6, p  {
    &:last-child {
      margin-bottom: 0px;
      &:first-child {
        margin-bottom: .75em;
      }
    }

  }
}


.arrowed-ul-before {
  position:relative;
  &:before {
    display: inline-block;
    position: absolute;
    line-height: 1;
    height: 1em;
    left : 0;
    width: 1em;
    font-family: @brand-icon-font;
    content: '\e960';
    top: 0;
    color: @brand-primary;
    margin-top: .5em;
    font-size: .75em;
    vertical-align: middle;
  }
}



.live-area {

    ul {
      list-style: none;
      padding-left: 0.125em;
      font-size: 1.125em;
      margin-bottom: .5em;
      font-family: @headings-font-family;
      font-weight: 400;
      /*     text-transform: uppercase; */
      li {
        padding: .125em;
        padding-left: 1.125em;
        .arrowed-ul-before();

        ul {
          margin-top:.25em;
          font-size: inherit;
          li {
            font-size: inherit;
          }
        }
      }
      + .btn {
        margin-top: .5em;
      }
    }

}


section > div.container {
  max-width:100%;
}

a.fulldiv-link {
  color: inherit;
  &:hover {
    color: inherit;
  }
}

blockquote {
  padding: (@line-height-computed / 2) @line-height-computed;
  margin: 0 0 @line-height-computed;
  font-size: @blockquote-font-size;
  border-left: 5px solid @blockquote-border-color;
  p {
    &:last-child{
      font-size:.75em;
      font-style: italic;
      &:only-child {
        font-size: 1em;
        font-style: normal;
      }
    }
  }
}

hr {
  margin-top: 1.7em;
  margin-bottom: 1.7em;
  border: 0;
  border-top: 1px solid @text-color;
}


hr {
  border-top: 1px solid @text-color;
}

.live-area {
  .next-prev-links {
    border-top: 1px solid @text-color;


    a.btn{

      margin:20px 0px 20px 0px;
      display: inline-block;
    }

  }
}