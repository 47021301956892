

@testvar : "asdfs";
//---------------------------------------------------------
//   BEGIN DOCUMENT HANDLING
//---------------------------------------------------------

@gray-base: #000001;
@gray-darker:            lighten(@gray-base, 13.5%); // #222 asdf
@gray-dark:              lighten(@gray-base, 20%);   // #333
@gray:                   lighten(@gray-base, 33.5%); // #555
@gray-light:             lighten(@gray-base, 46.7%); // #777
@gray-lighter:           lighten(@gray-base, 93.5%); // #eee

@brand-primary: #c3003e; // #fad72c
@brand-success: #c5f12c;
@brand-info:            #5bc0de;
@brand-warning: #f98521;
@brand-danger: #720007;

@brand-green: @brand-success;
@brand-black: #000;
@brand-gray-dark : #313842;
@brand-gray-light: #4e555e;
@brand-text-light: #aaaeb3;
@brand-gray-exlight: #e9eaeb;
@brand-text-offwhite: #fbfbfc;
//defines the half and half isntance colors
@input-color: @brand-gray-dark;
@bg-half-color: #ccc;


@padding-increment: 1.5em;
@sfix-pad : 1em;


// for border left border top classes
@border-color : @brand-primary;

@brand-icon-font: 'ICO';


//---------------------------------------------------------
//        Mobile Menu Variables
//---------------------------------------------------------


@menu-background: #fff;
@menu-color: @brand-black;
@transTime : .125s;
@mobile-item-padding: .375em;
@scrollLockColor: @brand-black;

//---------------------------------------------------------
//        Desktop Menu variabes
//---------------------------------------------------------

@top-nav-color : @brand-text-offwhite;
@top-nav-fszie : 1em;
@top-nav-bg :@brand-gray-dark;
@top-nav-hover: @brand-green;
@top-nav-font : @headings-font-family;
@top-nav-fweight : 400;
@top-nav-align : right;
@top-nav-social-color: @brand-primary;
@top-nav-social-hover: @brand-text-offwhite;

@header-logo: url('../images/logo-goutsi.svg');
@header-logo-fallback: url('../images/logo-goutsi-3x.png');
@header-logo-color-fallback: #fff;
@logo-height: 96px;
@logo-width: 175px;
@logo-adjusted-height: (@logo-height * .75);
@logo-adjusted-width: (@logo-width * .75);
@logo-mobile-height: (@logo-height * .5);
@logo-mobile-width: (@logo-width * .5);

@main-nav-family : @headings-font-family;
@main-nav-fsize: 1.25em;
@main-nav-fsize-small: 1em;
@main-nav-fweight: 800;
@main-nav-height: @logo-height;
@main-nav-adjusted-height: @logo-adjusted-height;
@main-nav-color: @brand-gray-dark;
@main-nav-color-hover: @brand-gray-light;
@main-nav-top-level-padding: 1.25em 1.25em 1.25em 1.25em;
@perpval : 1000px; // determines perspecitve of transform

@sub-menu-family: @headings-font-family;
@sub-menu-top: @main-nav-height;
@sub-menu-left: (@sub-menu-width * -.45); 
@sub-menu-adjusted-top: @main-nav-adjusted-height;
@sub-menu-padding : 0em 0em;
@sub-menu-fsize: .75em;
@sub-menu-fweight: 400;
@sub-menu-width: 250px;
@sub-menu-width-adjusted: 225px;
@sub-menu-left-adjusted: (@sub-menu-width-adjusted * -.45);

@sub-menu-transition-max-height : 300px;
@sub-menu-bg: fade(@brand-gray-dark,80%);
@sub-menu-color: @brand-text-offwhite;
@sub-menu-color-hover :@brand-gray-dark;
@sub-menu-link-padding: 1em 1em 1em 2.25em;
@sub-menu-seperator-color: rgba(255,255,255,.5);
@sub-menu-transeffect-color: @brand-text-offwhite;


//---------------------------------------------------------
//        Footer variabes
//---------------------------------------------------------

@footer-color-2 : @brand-gray-light;
@footer-color-1: @brand-gray-dark;
@footer-hover: @brand-green;

@footer-before-text: @brand-text-offwhite ;
@footer-text:  @brand-text-light;


@footer-logo: url('../images/logo-goutsi-white.svg');
@footer-logo-fallback: url('../images/logo-goutsi-white-3x.png');
@footer-logo-color-fallback: @brand-gray-dark;
@footer-logo-height: 96px;
@footer-logo-width: 149px;


//---------------------------------------------------------
//        Form variabes
//---------------------------------------------------------

@form-color : @brand-black;
@form-border : @brand-black;
@form-background : @brand-primary;





// TRIANGLE SIZE IN BUTTON
@tri-width: .5em;

//padding-size variables

@xs-pad : .5;
@sm-pad : 1;
@md-pad : 1.25;
@lg-pad : 1.5;
@xl-pad : 1.75;
@no-pad : 0;
@reg-pad : .5;




//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
@body-bg:               #fff;
//** Global text color on `<body>`.
@text-color:            @brand-gray-dark;

//** Global textual link color.
@link-color:            @brand-primary;
//** Link hover color set via `darken()` function.
@link-hover-color:      darken(@link-color, 15%);
//** Link hover decoration.
@link-hover-decoration: none;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

@font-family-sans-serif:  'Helvetica Body', Helvetica, Arial, sans-serif;
@font-family-serif:       "Helvetica Heading",Helvetica, Arial, sans-serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

// SETS DEFAULT SIZE THAT ALL EMS ARE BASED UPON.
@base-em-size: 16px ;

@font-size-base:          1em;
@font-size-large:         (@font-size-base * 1.125); // ~18px
@font-size-small:         (@font-size-base * 0.875); // ~12px

@font-size-h1:            (@font-size-base * 4.285714285714286); // ~36px
@font-size-h2:            (@font-size-base * 2); // ~30px 
@font-size-h3:            (@font-size-base * 1.5); // ~24px
@font-size-h4:            (@font-size-base * 1.25); // ~18px
@font-size-h5:            (@font-size-base * 1.125);
@font-size-h6:            @font-size-base; // ~12px

// text - size modidfers these are wrapping classes that balloon all text sizes relatively within them.
@text-xs: .5em;
@text-small: .875em;
@text-reg: 1rem;
@text-large: 1.25em;
@text-xlarge: 1.5em;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.
@btn-font-family: @headings-font-family;
@btn-font-weight:                700;

//@btn-default-color:              #fff;
//@btn-default-bg:                 #000;
//@btn-default-border:             #000;
//@btn-default-line-color:             #000;


//@btn-primary-color:              #fff;
//@btn-primary-bg:                 @brand-primary;
//@btn-primary-border:             darken(@btn-primary-bg, 5%);

@btn-success-color:              #fff;
@btn-success-bg:                 @brand-success;
@btn-success-border:             darken(@btn-success-bg, 5%);

@btn-info-color:                 #fff;
@btn-info-bg:                    @brand-info;
@btn-info-border:                darken(@btn-info-bg, 5%);

@btn-warning-color:              #fff;
@btn-warning-bg:                 @brand-warning;
@btn-warning-border:             darken(@btn-warning-bg, 5%);

@btn-danger-color:               #fff;
@btn-danger-bg:                  @brand-danger;
@btn-danger-border:              darken(@btn-danger-bg, 5%);

@btn-link-disabled-color:        @gray-light;



@btn-brand-color:               #fff;
@btn-brand-line-color:          @brand-primary;
@btn-brand-bg:                  @brand-primary;
@btn-brand-border:              @brand-primary;
@btn-brand-beforecolor:         @btn-brand-color;


@btn-default-color:              #fff;
@btn-default-bg:                 @brand-gray-dark;
@btn-default-border:             @brand-gray-dark;
@btn-default-line-color:         @brand-gray-dark;
@btn-default-beforecolor :  #fff;




@btn-green-color:               #fff;
@btn-green-line-color:          @brand-green;
@btn-green-bg:                  @brand-green;
@btn-green-border:              @brand-green;
@btn-green-beforecolor:              @btn-green-color;

@btn-white-color:               #000;
@btn-white-line-color:          #fff;
@btn-white-bg:                  #fff;
@btn-white-border:              #fff;

@btn-black-color:               #fff;
@btn-black-line-color:          #000;
@btn-black-bg:                  #000;
@btn-black-border:              #000;

@btn-line-bg: transparent;
@btn-line-width: 1px;




@btn-padding-base-vertical:     .5em;
@btn-padding-base-horizontal:   1em;


@letter-spacing-correction: -0em;
@tex-indent-correction: -.05em;


body *::selection {
  background:@brand-green;
  color: @brand-gray-dark;
}

@blockquote-small-color:      @brand-gray-dark;
//** Blockquote font size
@blockquote-font-size:        (@font-size-base * 1.125);
//** Blockquote border color
@blockquote-border-color:     @brand-green;



@image-offset : 1.5em;