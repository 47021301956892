.border-right {
   border-right: 1px solid;
   @media( max-width: @screen-md-min){
     border-bottom: 1px solid;
     border-right:0px;
   }
 }
.border-left {
  border-left: 1px solid;
  @media( max-width: @screen-md-min){
    border-top: 1px solid;
    border-left:0px;
  }
}