@icon-folder : '../images/svg/';


.modifier-triangle {
  position:relative;

  &:before {
    display:block;
    position:absolute;
    width:25%;
    height:0px;
    padding-bottom: 25%;
    content:'';
    line-height:0px;
  }
}

.modifier-triangle2 (@width){
  position:relative;

  &:before {
    display:block;
    position:absolute;
    width:@width;
    height:0px;
    padding-bottom: @width;
    content:'';
    line-height:0px;
  }
}
.modifier-ur(@fore,@back)  {
  background: url("@{icon-folder}@{fore}") 75% 20%/ 30% no-repeat, url("@{icon-folder}@{back}") 0 0/ 100% no-repeat;
  top:0; right:0;
}
.modifier-ul(@fore,@back) {
  background: url("@{icon-folder}@{fore}") 20% 20%/ 30% no-repeat, url("@{icon-folder}@{back}") 0 0/ 100% no-repeat;
  top:0; left:0;
}
.modifier-ll(@fore,@back) {
  background: url("@{icon-folder}@{fore}") 25% 80%/ 30% no-repeat, url("@{icon-folder}@{back}") 0 0/ 100% no-repeat;
  bottom:0; left:0;
}
.modifier-lr(@fore,@back) {
  background: url("@{icon-folder}@{fore}") 85% 80%/ 30% no-repeat, url("@{icon-folder}@{back}") 0 0/ 100% no-repeat;
  bottom:0; right:0;
}


.triangle-lock-ur-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ur('i_lock-yellow.svg','i_tri-ur-b.svg'); }
  }

.triangle-lock-ul-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ul('i_lock-yellow.svg','i_tri-ul-b.svg'); }
  }
.triangle-lock-lr-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-lr('i_lock-yellow.svg','i_tri-lr-b.svg'); }
  }
.triangle-lock-ll-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ll('i_lock-yellow.svg','i_tri-ll-b.svg'); }
  }

// Residential


.triangle-residential-ur-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ur('i_residential_residential.svg','i_tri-ur-b.svg'); }
  }

.triangle-residential-ul-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ul('i_residential_residential.svg','i_tri-ul-b.svg'); }
  }
.triangle-residential-lr-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-lr('i_residential_residential.svg','i_tri-lr-b.svg'); }
  }
.triangle-residential-ll-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ll('i_residential_residential.svg','i_tri-ll-b.svg'); }
  }



// Comercial
.triangle-commercial-ur-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ur('i_comerical_comerical.svg','i_tri-ur-b.svg'); }
  }

.triangle-commercial-ul-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ul('i_comerical_comerical.svg','i_tri-ul-b.svg'); }
  }
.triangle-commercial-lr-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-lr('i_comerical_comerical.svg','i_tri-lr-b.svg'); }
  }
.triangle-commercial-ll-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ll('i_comerical_comerical.svg','i_tri-ll-b.svg'); }
  }

// Security Guards
.triangle-security-ur-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ur('i_security_security.svg','i_tri-ur-b.svg'); }
  }

.triangle-security-ul-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ul('i_security_security.svg','i_tri-ul-b.svg'); }
  }
.triangle-security-lr-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-lr('i_security_security.svg','i_tri-lr-b.svg'); }
  }
.triangle-security-ll-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ll('i_security_security.svg','i_tri-ll-b.svg'); }
  }

// Medical
.triangle-medical-ur-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ur('i_medical_medical.svg','i_tri-ur-b.svg'); }
  }

.triangle-medical-ul-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ul('i_medical_medical.svg','i_tri-ul-b.svg'); }
  }
.triangle-medical-lr-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-lr('i_medical_medical.svg','i_tri-lr-b.svg'); }
  }
.triangle-medical-ll-yellow {
  padding:0px 0px 0px 0px;
  .modifier-triangle();
  &:before { .modifier-ll('i_medical_medical.svg','i_tri-ll-b.svg'); }
  }
