a.article-link {
  width: 100%;
  margin-bottom:5px;
  font-size: .75em;
  display:inline-block;
  span {

    margin-right:10px;

  }
}
.live-area .toolkit {
  h3 {
    width: 100%;
    border-bottom:1px solid #fff;
    padding-bottom:3px;
    margin-bottom:3px;
  }

}
.live-area {
  ul.horizontal-list {
    padding-left: 0px;
    text-align: left;
    li:before{
      content: '';
      display: none;
    }
    li {
      display: inline-block;
      width:auto;
      padding-left: 0px;
      margin-right: 5px;
      margin-bottom:5px;

    }
  }
}
div.stockinfo {
  margin-top: 25px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #fff;
  margin-bottom:25px;
  .price-row {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    .price {
      line-height: 1em;
      font-size: 2em;
      font-weight: 600;
    }
    .plus {
      color: @brand-green;
      &.neg { color: @brand-primary}
    }
    .percent {
      color: @brand-green;
      &.neg { color: @brand-primary}
    }
  }
}

.investor-menu {
  margin-top: -25px;
  padding-top:25px;
  border-bottom: 1px solid @text-color;
  margin-bottom:25px;
  padding-bottom:25px;
  ul {
    margin-bottom: 0px;
  }

}