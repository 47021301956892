#content.archive-downloads {
  article {
    display: inline-block;
    padding: 25px 0px;
    z-index: 1;
     border-bottom: 1px solid @text-color;

 
    &:last-child{
      border-bottom:0px solid;
    }
    a.fulldiv-link {
      padding: 1em;
      display: inline-block;
      cursor: pointer;
      &:after {
        position:absolute;
        font-family: @headings-font-family;
        top:0;
        right: 0;
        font-size: 1em;
        padding: 10px;

        background: @brand-gray-dark;
        opacity: 0;
        color: @brand-green;
        content: 'Get This Download';
        transition: opacity .25s;
        z-index: 2;
      }
      &:before{
        position:absolute;
        top:0;
        right: 0;
        width: 100%;
        height: 100%;
        position:absolute;
        z-index: 1;

        background: @brand-gray-dark;
        opacity: 0;
        content: '';
        transition: opacity .25s;
      }
      &:hover {
        &:after { opacity: 1;}
        &:before {
          opacity: .25;
        }

      }
    }

    .file-col {
      text-align: center;
      border-right:1px solid @brand-gray-dark;

      .file-type {

        color: #fff;
        width: 80px;
        height: 80px;
        display: inline-block;

        &:after {
          width: 100%;
          height: 100%;
          display: block;
          content: '';
          padding: 10px;
          line-height: 60px;
          font-size: 60px;
          color: @brand-primary;
          font-family: @brand-icon-font;


        }
        &.png,&.jpg,&.gif,&.peg {
          &:after {
            content: '\e982';
          }
        }
        &.zip {
          &:after {
            content: '\e983';
          }
        }
        &.txt {
          &:after {
            content: '\e984';
          }
        }
        &.doc,&.ocx,&.rtf {
          &:after {
            content: '\e985';
          }
        }
        &.ppt,&.ttx,&.odp,&.pps {
          &:after {
            content: '\e986';
          }
        }
        &.pdf {
          &:after {
            content: '\e987';
          }
        }
        &.avi,&.mp4,&.webm {
          &:after {
            content: '\e988';
          }
        }
        &.mp3,&.aac {
          &:after {
            content: '\e98d';
          }
        }
      
      }
    }
    @media(max-width:@screen-sm-min) {
      .file-col {
        display: none;
      }
    }
  }

}

#content.category-template {
  article {

    .heading-row{
        z-index: 1;
        border-bottom: 1px solid @text-color;
        
    }
    &:last-child .heading-row{ border-bottom:0px solid; }
  }
}

.heading-row-single-download {
  @media(min-width:@screen-md-min) {
    display:flex;
    align-items: center;
  }
  img {
    margin-top:20px;
    margin-bottom:20px;
    max-width:250px;
    max-height:300px;
    width:auto;
    height:auto;

  }
  @media(max-width: @screen-sm-max) {
    img {max-width: 100%;}
    h1 {
      font-size: 2.25em;
    }
  }
}

.row.awards-rows {

    display:flex;
    align-items: flex-start;
  flex-wrap: wrap;


  p.company-title {

    line-height: 1em;
    font-family: @headings-font-family;
  }

}