.pad-mixin2(@size,@axis) {
  @c: ~"@{size}";
  .@{c}pad ,div.@{c}pad, .row.@{c}pad, .container.@{c}pad {
    padding: (@axis * @grid-gutter-width );
    > .row {
      margin-left: (@grid-gutter-width * @axis * -1);
      margin-right: (@grid-gutter-width * @axis * -1);
    }
  }
  .@{c}pad-horz ,div.@{c}pad-horz , .row.@{c}pad-horz , .container.@{c}pad-horz {
    padding-left: (@axis * @grid-gutter-width ); padding-right: (@axis * @grid-gutter-width ) ;
    > .row {
      margin-left: (@grid-gutter-width * @axis * -1);
      margin-right: (@grid-gutter-width * @axis * -1);
    }
  }
  .@{c}pad-vert ,div.@{c}pad-vert , .row.@{c}pad-vert , .container.@{c}pad-vert { padding-top:(@axis * @grid-gutter-width ); padding-bottom:(@axis * @grid-gutter-width ) ;}
  .@{c}pad-top ,div.@{c}pad-top , .row.@{c}pad-top , .container.@{c}pad-top { padding-top:(@axis * @grid-gutter-width ); ;}
  .@{c}pad-bottom ,div.@{c}pad-bottom , .row.@{c}pad-bottom , .container.@{c}pad-bottom { padding-bottom:(@axis * @grid-gutter-width ); ;}
}


.pad-mixin3(@size,@axis) {
  @c: ~"@{size}";
  .pad@{c} ,div.pad@{c}, .row.pad@{c}, .container.pad@{c} {
    padding: (@axis * @grid-gutter-width );
    > .row {
      margin-left: (@grid-gutter-width * @axis * -1);
      margin-right: (@grid-gutter-width * @axis * -1);
    }
  }
  .pad@{c}-horz ,div.pad@{c}-horz , .row.pad@{c}-horz , .container.pad@{c}-horz {
    padding-left: (@axis * @grid-gutter-width ); padding-right: (@axis * @grid-gutter-width ) ;
    > .row {
      margin-left: (@grid-gutter-width * @axis * -1);
      margin-right: (@grid-gutter-width * @axis * -1);
    }
  }
  .pad@{c}-vert ,div.pad@{c}-vert , .row.pad@{c}-vert , .container.pad@{c}-vert { padding-top:(@axis * @grid-gutter-width ); padding-bottom:(@axis * @grid-gutter-width ) ;}
}

.pad-mixin(@size,@axis,@offaxis) {
  @sized: "screen-@{size}-min";
  @media  ( min-width: @@sized) {
    padding-@{axis}: (@grid-gutter-width /2 );
    padding-@{offaxis}: 0px;
  }
}

//  padding size modifiers used to add support for xs thru xl-pad xl-pad-horz etc.
//  padding classes worked out to be the following.
//  xs-pad, xs-pad-hrz, xs-pad-vert.
//  these padding styles will effect any div container etc.
.pad-mixin2(~'xs-',@xs-pad);
.pad-mixin2(~'sm-',@sm-pad);
.pad-mixin2(~'md-',@md-pad);
.pad-mixin2(~'lg-',@lg-pad);
.pad-mixin2(~'xl-',@xl-pad);
.pad-mixin2(~'no-',@no-pad);
.pad-mixin2(~'reg-',@reg-pad);
.pad-mixin2(~'',@reg-pad);

.pad-mixin3(~'-xs',@xs-pad);
.pad-mixin3(~'-sm',@sm-pad);
.pad-mixin3(~'-md',@md-pad);
.pad-mixin3(~'-lg',@lg-pad);
.pad-mixin3(~'-xl',@xl-pad);
.pad-mixin3(~'-no',@no-pad);
.pad-mixin3(~'-reg',@reg-pad);
.pad-mixin3(~'',@reg-pad);

// padding modifer classes to be used to add padding to a side of a block from screen size and above.
.pad-left-xs { .pad-mixin(~'sm',~'left',~'right'); }
.pad-right-xs { .pad-mixin(~'sm',~'right',~'left'); }
.pad-left-sm { .pad-mixin(~'sm',~'left',~'right'); }
.pad-right-sm { .pad-mixin(~'sm',~'right',~'left'); }
.pad-left-md { .pad-mixin(~'md',~'left',~'right'); }
.pad-right-md { .pad-mixin(~'md',~'right',~'left'); }
.pad-left-lg { .pad-mixin(~'lg',~'left',~'right'); }
.pad-right-lg { .pad-mixin(~'lg',~'right',~'left'); }

