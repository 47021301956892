
.navbar {
  z-index: 1;
  display: block;
  min-height: 0px;
}

.nav {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  li {
    z-index: 1;
    display: inline-block;
    width: auto;
    position: relative;
    a {
      display: inline-block;
      width: 100%;
    }

    ul {
      z-index: 2;
      position: absolute;
      top: 0px;
      left: 0px;
      display: none;
      li {
        width: 100%;
        a {
          width: 100%;
        }
      }
    }

    a:hover, a:focus, a:active {
      + ul {
        display: block;
      }
    }
    &:hover, &:focus, &:active {
      ul {
        display: block;
      }
    }

  }
}

#top-nav, #main-nav {
  font-family: @headings-font-family;
  text-transform: normal;
  border: 0px solid;
  position: relative;
  z-index: 6;
  > div.container {
    max-width:100%;
  }
}

#top-nav {
  background: @top-nav-bg;
  font-size: @top-nav-fszie;
  font-weight: @top-nav-fweight;
  font-family: @top-nav-font;
  color: @top-nav-color;
  height: auto;
  text-align: @top-nav-align;
  .menu-top-nav-container {
    width: auto;
    vertical-align: middle;
    display: inline-block;
    float: none;
  }
  #menu-top-nav {
    width: auto;
    vertical-align: middle;
    display: inline-block;
    float: none;
  }
  ul.nav {
    li.active a {
    }
    li {
      a {
        display: inline-block;
        font-weight: inherit;
        font-size: inherit;
        font-family: inherit;
        color: @top-nav-color;
        padding: .5em;
        &:hover {
          color: @top-nav-hover;
        }
      }
    }
  }
  .social-links {
    display: inline-block;
    margin-left: 1em;
    .social-link {

      background: @top-nav-social-color;
      border-left-color: @top-nav-social-color;
      border-right-color: @top-nav-social-color;
      &:before {
        border-bottom-color: @top-nav-social-color;
      }
      &:after {
        border-top-color: @top-nav-social-color;
      }
      i {
        color: @top-nav-color;
      }

      &:hover, &:focus, &:active {
        background: @top-nav-social-hover;
        border-left-color: @top-nav-social-hover;
        border-right-color: @top-nav-social-hover;
        &:before {
          border-bottom-color: @top-nav-social-hover;
        }
        &:after {
          border-top-color: @top-nav-social-hover;
        }
        i {
          color: @top-nav-bg;
        }
      }
    }
  }
}

#main-nav {
  z-index: 9;
  font-size: @main-nav-fsize;
  line-height: .9;
  @media (max-width: @screen-md-max) {
    font-size: @main-nav-fsize-small;
  }
  position: relative;

  .brand-container {
    display: inline-block;
    vertical-align: middle;
    a.navbar-brand {
      display: inline-block;
      vertical-align: middle;
      padding: 0px;
      margin: 0px;
      #logo {
        //height: 96px;
        //width: 175px;
        height: @logo-height;
        width: @logo-width;
        @media (max-width: @screen-md-max) {
          height: @logo-adjusted-height;
          width: @logo-adjusted-width
        }
        @media (max-width: @screen-sm-max) {
          height: @logo-mobile-height;
          width: @logo-mobile-width
        }
        position: relative;
        background: @header-logo-fallback, @header-logo-color-fallback;
        background: @header-logo, @header-logo-color-fallback;
        background-size: cover;
        display: block;

      }
    }
  }

  .navbar-collapse {
    position: relative;
    display: inline-block;
    .menu-main-menu-container {
      display: inline-block
    }
  }

  li a {
    display: inline-block;
    width: 100%;
    .arrowed-link-before();

  }

  //-- FIRST LEVEL STYLING ONLY

  #menu-main-menu {
    position: relative;
    > li {
      font-family: @main-nav-family;
      font-weight: @main-nav-fweight;
      height: @main-nav-height;
      @media (max-width: @screen-md-max) {
        height: @main-nav-adjusted-height;
      }
      &:before {
        height: @main-nav-height;
        display: inline-block;
        vertical-align: middle;
        margin: 0px;
        content: '';
        width: 0px;
        @media (max-width: @screen-md-max) {
          height: @main-nav-adjusted-height;
        }
      }
      > ul {

        display: block;
        visibility: hidden;
        overflow: hidden;
        transform-origin: 50% 0;
        transform: rotateX(-90deg);
        transition: transform .25s ease-in-out;
        left: 50%;
        margin-left: @sub-menu-left;
        top: @sub-menu-top;
        width: @sub-menu-width;

        box-shadow: 1px 4px 3px rgba(0, 0, 0, .25);
        @media (max-width: @screen-md-max) {
          top: @sub-menu-adjusted-top;
          width: @sub-menu-width-adjusted;
          margin-left: @sub-menu-left-adjusted;
        }

      }
      > a {
        display: inline-block;
        vertical-align: middle;
        float: none;
        padding: @main-nav-top-level-padding;

      }
      > a {
        color: @main-nav-color;
        &:hover, &:focus, &:active {
          color: @main-nav-color-hover;
        }
        &:before {
          color: @brand-primary;
        }
      }
      &:hover > a, &:focus > a, &:active > a {
        color: @main-nav-color;
        &:hover, &:focus, &:active {
          color: @main-nav-color-hover;
        }
      }

    }
    /*     > li:hover ul { visibility: visible; max-height:@sub-menu-transition-max-height;} */
    /*     > li a:hover + ul { visibility: visible; max-height:@sub-menu-transition-max-height;} */

    > li:hover ul, > li a:hover + ul, > li:focus ul, > li a:focus + ul > li:active ul, > li a:active + ul {
      visibility: visible;
      transform: rotateX(0deg);
    }

  }

  // HOVER DISPLAY -----------------------------------------------------------------------------------------------------

  li {
    ul {

      padding: @sub-menu-padding;
      background: @sub-menu-bg;
      font-size: @sub-menu-fsize;
      li {
        font-family: @sub-menu-family;
        font-weight: @sub-menu-fweight;
        border-top: 1px solid @sub-menu-seperator-color;
        &:hover, &:focus, &:active {

          color: @sub-menu-color-hover;;
          a {
            color: @sub-menu-color-hover;
          }
        }
        a {
          width: auto;
          overflow: visible;
          //margin-top:5px;
          //margin-bottom:5px;
          padding: @sub-menu-link-padding;
          color: @sub-menu-color;
          &:hover, &:focus, &:active {
            color: @sub-menu-color-hover;
          }
          &:before {
            transition: margin-left .5s;
          }
        }
        &:first-child {
          border-top: 0px solid transparent;
        }
      }
    }
  }
  // arrow support ------------------------------------

  li ul li a:hover, li ul li:hover a,
  li ul li a:active, li ul li:active a,
  li ul li a:focus, li ul li:focus a {
    &:before {
      margin-left: 82%;
    }
    &:after {
      //left: -10%;

      transform: translateX(-19.5%);
    }

  }
  li ul li {
    vertical-align: top;
    overflow: hidden;
    a {

      &:after {
        font-family: @brand-icon-font;
        color: @sub-menu-transeffect-color;
        display: inline-block;
        position: absolute;
        content: '\e963';
        z-index: -1;

        font-size: 20em;
        line-height: 0px;

        transform: translateX(-101%);
        height: 0em;
        width: 1em;

        left: 0px;
        top: 53%;
        margin-top: 0;

        transition: transform 0.35s ease-out;

      }
    }
  }

}

.heading-wrap {
  .bg-image-dark();
  .bg-cover();
  .bg-center();
   position: relative;
  background-size: cover !important;
  &:after {
    position: absolute;
    bottom: -1px;
    right: 0;
    font-size: 3em;
    line-height: 0;
    vertical-align: bottom;
    width: 1em;
    height: .5em;
    color: #fff;
    content: '\e961';
    font-family: @brand-icon-font;
  }
  .heading-row {

    padding-top:3em;
    .page-desc {
      padding-left:0em;
      padding-right:.25em;
      font-size:1.25em;
      letter-spacing: .025em;

    }
  }
  .breadcrumb-row {
    padding: 2em 0em ;
    i.b-icon {
      color: @brand-text-offwhite;
      &:before{
        margin-left: .5em;
        margin-right: .5em;
        vertical-align: .125em;
        font-size: .5em;
      }
    }
    a {
      color: @brand-green;

      &:hover {
        color: ligthen(@brand-green, 30%);
      }
    }
  }
}

.single-format-standard  {
  .heading-row {
    h1 {
      font-size :2.5em;
    }
  }
}

@media ( max-width: @screen-sm-min) {
  .heading-wrap, .single-format-standard {
    .heading-row {
      h1 {
        font-size: 2.25em;
        word-wrap: break-word;
      }
      .page-desc {
        font-size: 1em;
      }
      padding-top: 3em;
      padding-bottom: 3em;
    }
    .breadcrumb-row {
      display: none;
    }

  }

}