//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.btn {
  transition: color .5s;
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: @btn-font-weight;
  font-family: @btn-font-family;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;

  white-space: nowrap;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  //white-space: nowrap;
  span { vertical-align:middle; display:inline-block;
    line-height:.95em;
  &:before {
    height: 100%;
    content: '';
    display:block;
    position:absolute;
    background: transparent;
    width: 1px;
    left:0;
    top:0;
  }
  }
  .button-size(@btn-padding-base-vertical; @btn-padding-base-horizontal; @font-size-base; @btn-ine-height-base;  @btn-border-radius-base);
  .user-select(none);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      .tab-focus();
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: @btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    .box-shadow(inset 0 3px 5px rgba(0, 0, 0, .125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: @cursor-disabled;
    .opacity(.65);
    .box-shadow(none);
  }

  a& {
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }
}



// Alternate buttons
// --------------------------------------------------

.btn-default {
  .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
  .btn-arrows();
  &.btn-line{
    .button-variant-line(@btn-default-line-color; @btn-default-bg; @btn-default-border; @btn-default-beforecolor);

  }
}

.btn-default.btn-brand,.btn-default.btn-primary , .btn-brand,.btn-primary  {
  .button-variant(@btn-brand-color; @btn-brand-bg; @btn-brand-border);
  .btn-arrows();
  &.btn-line{
    .button-variant-line(@btn-brand-line-color; @btn-brand-bg; @btn-brand-border ; @btn-brand-beforecolor);

  }
}


.btn-green ,.btn-default.btn-green{
  .button-variant(@btn-green-color; @btn-green-bg; @btn-green-border);
  .btn-arrows();
  &.btn-line{
    .button-variant-line(@btn-green-line-color; @btn-green-bg; @btn-green-border ; @btn-green-beforecolor);

  }
}

//
//.btn-white {
//  .button-variant(@btn-white-color; @btn-white-bg; @btn-white-border);
//  &.btn-line{
//    .button-variant-line(@btn-white-line-color; @btn-white-bg; @btn-white-border);
//  }
//}
//
//.btn-black {
//  .button-variant(@btn-black-color; @btn-black-bg; @btn-black-border);
//  &.btn-line{
//    .button-variant-line(@btn-black-line-color; @btn-black-bg; @btn-black-border);
//  }
//}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: @link-color;
  font-weight: normal;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    .box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: @link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  .button-size(@btn-padding-base-vertical; @btn-padding-base-horizontal; @font-size-large; @line-height-large; @btn-border-radius-large);
}

.btn-sm {
  // line-height: ensure proper height of button next to small input
  .button-size(@btn-padding-base-vertical; @btn-padding-base-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}

.btn-xs {
  .button-size((@btn-padding-base-vertical* .5); (@btn-padding-base-horizontal * .5); @font-size-small; @line-height-small; @btn-border-radius-small);
}

// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
div + .btn {

}
.form-inline {
  .btn {
    margin-top:0px;
  }
}
